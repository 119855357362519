var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: "emailSetup" } },
    [
      _c(
        "v-form",
        [
          _c(
            "v-card-text",
            { staticClass: "pa-2" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _vm.error
                        ? _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "red--text font-weight-bold text-left"
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "red" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                mdi-alert-circle-outline\n              "
                                    )
                                  ]
                                ),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.error) +
                                    "\n            "
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0",
                          attrs: { cols: "12", sm: "12", lg: "12" }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "pl-3" },
                                [
                                  _vm._v(
                                    "\n                Mailing " +
                                      _vm._s(
                                        _vm.$_splitMixin_isSplitTest
                                          ? "Test Splits"
                                          : _vm.$_splitMixin_isTrueSplit
                                          ? "Splits"
                                          : ""
                                      ) +
                                      "\n                "
                                  ),
                                  _c("v-spacer"),
                                  _vm.isMMSAdmin
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var onTooltip = ref.on
                                                  return [
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        attrs: {
                                                          right: "",
                                                          "x-offset": "",
                                                          origin: "right",
                                                          transition:
                                                            "slide-x-transition",
                                                          "close-on-content-click": false,
                                                          "close-on-click": false
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var onMenu =
                                                                  ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "mr-2",
                                                                        attrs: {
                                                                          "x-small":
                                                                            "",
                                                                          fab:
                                                                            "",
                                                                          color:
                                                                            "info"
                                                                        }
                                                                      },
                                                                      Object.assign(
                                                                        {},
                                                                        onMenu,
                                                                        onTooltip
                                                                      )
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-information"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value: _vm.adminInfo,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.adminInfo = $$v
                                                          },
                                                          expression:
                                                            "adminInfo"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-card",
                                                          [
                                                            _c(
                                                              "v-card-text",
                                                              {
                                                                staticClass:
                                                                  "px-5 py-2"
                                                              },
                                                              [
                                                                _c(
                                                                  "LabelValue",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Campaign Id",
                                                                      value: _vm.$store.getters[
                                                                        "mailing/getCampaignId"
                                                                      ]()
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "LabelValue",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Tenant CRM Id",
                                                                      value: _vm.$store.getters[
                                                                        "mailing/getMailingTenantCrmId"
                                                                      ]()
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "LabelValue",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Target Id",
                                                                      value: _vm.$store.getters[
                                                                        "mailing/getTargetId"
                                                                      ]()
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "LabelValue",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Mailing Id",
                                                                      value: _vm.$store.getters[
                                                                        "mailing/getMailingItemId"
                                                                      ]()
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._l(
                                                                  _vm.splitMixin_splits,
                                                                  function(
                                                                    split,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "LabelValue",
                                                                      {
                                                                        key:
                                                                          "admin-split-" +
                                                                          i,
                                                                        attrs: {
                                                                          label:
                                                                            "Split Id " +
                                                                            _vm
                                                                              .$_splitMixin_splitLetters[
                                                                              i
                                                                            ],
                                                                          value:
                                                                            split.id
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                )
                                                              ],
                                                              2
                                                            ),
                                                            _c("v-divider"),
                                                            _c(
                                                              "v-card-actions",
                                                              [
                                                                _c("v-spacer"),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "tertiary"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.adminInfo = false
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            Close\n                          "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2199183062
                                          )
                                        },
                                        [_c("span", [_vm._v("Admin Details")])]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.$_splitMixin_isMaxSplits ||
                                                        _vm.isLoading() ||
                                                        _vm.splitMixin_splits
                                                          .length == 4 ||
                                                        _vm.isClicked,
                                                      "x-small": "",
                                                      fab: "",
                                                      color: "success"
                                                    },
                                                    on: { click: _vm.addSplit }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-plus")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [_c("span", [_vm._v("Add Split")])]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.$_splitMixin_isSplit,
                                          expression: "$_splitMixin_isSplit"
                                        }
                                      ],
                                      staticClass: "ml-2",
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.showSplits = !_vm.showSplits
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.showSplits
                                              ? "mdi-chevron-up"
                                              : "mdi-chevron-down"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.$_splitMixin_isSplit
                                ? _c(
                                    "v-card-subtitle",
                                    {
                                      staticClass:
                                        "pt-2 px-10 d-flex align-center",
                                      staticStyle: { height: "54px" }
                                    },
                                    [
                                      _vm._l(_vm.splitMixin_splits, function(
                                        split,
                                        i
                                      ) {
                                        return _c(
                                          "span",
                                          {
                                            key: "splits-" + i,
                                            style:
                                              "width: " +
                                              split.distribution +
                                              "%"
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-progress-linear",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  striped: "",
                                                                  height:
                                                                    _vm.focusedSplit ===
                                                                    i
                                                                      ? "30"
                                                                      : "20",
                                                                  rounded:
                                                                    _vm.focusedSplit ===
                                                                    i,
                                                                  value: "100",
                                                                  color:
                                                                    _vm
                                                                      .$_splitMixin_splitColors[
                                                                      i
                                                                    ]
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var value =
                                                                          ref.value
                                                                        return [
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  split.distribution
                                                                                ) +
                                                                                  "%"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      split.subject ||
                                                        "Subject " +
                                                          _vm.$_splitMixin_indexToChar(
                                                            i
                                                          )
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-progress-linear",
                                                      _vm._g(
                                                        {
                                                          style:
                                                            "width: " +
                                                            _vm.$_splitMixin_winningDistribution +
                                                            "%",
                                                          attrs: {
                                                            height:
                                                              _vm.focusedSplit ===
                                                              -1
                                                                ? "30"
                                                                : "20",
                                                            rounded:
                                                              _vm.focusedSplit ===
                                                              -1,
                                                            value: "100",
                                                            color:
                                                              _vm.$_splitMixin_winningColor
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var value =
                                                                    ref.value
                                                                  return [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$_splitMixin_winningDistribution
                                                                          ) +
                                                                            "%"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            955329514
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.winningCriteria &&
                                                  _vm.$_splitMixin_winningCriteriaSelect.find(
                                                    function(x) {
                                                      return (
                                                        x.value ===
                                                        _vm.winningCriteria
                                                      )
                                                    }
                                                  )
                                                  ? _vm.$_splitMixin_winningCriteriaSelect.find(
                                                      function(x) {
                                                        return (
                                                          x.value ===
                                                          _vm.winningCriteria
                                                        )
                                                      }
                                                    ).text
                                                  : ""
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.$_splitMixin_isSplit
                                ? _c(
                                    "v-card-text",
                                    {
                                      staticClass: "px-10 d-flex align-center"
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "4",
                                                sm: "3",
                                                md: "2"
                                              }
                                            },
                                            [
                                              _c("v-progress-linear", {
                                                attrs: {
                                                  height: "20",
                                                  value:
                                                    _vm.$_splitMixin_winningDistribution,
                                                  color:
                                                    _vm.$_splitMixin_winningColor
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var value = ref.value
                                                        return [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$_splitMixin_winningDistribution
                                                              ) + "%"
                                                            )
                                                          ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1428742489
                                                )
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "9", lg: "3" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items:
                                                    _vm.$_splitMixin_winningCriteriaSelect,
                                                  disabled: !_vm.$_splitMixin_isSplitTest,
                                                  dense: "",
                                                  color:
                                                    _vm.$_splitMixin_winningColor,
                                                  "item-color":
                                                    _vm.$_splitMixin_winningColor,
                                                  label: "Winning Criteria",
                                                  "error-messages":
                                                    _vm.winningCriteriaErrors
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.$v.winningCriteria.$touch()
                                                  },
                                                  blur: function($event) {
                                                    _vm.$v.winningCriteria.$touch()
                                                    _vm.focusedSplit = null
                                                    _vm.setMailingValue(
                                                      "winningCriteria"
                                                    )
                                                  },
                                                  focus: function($event) {
                                                    _vm.focusedSplit = -1
                                                  }
                                                },
                                                model: {
                                                  value: _vm.winningCriteria,
                                                  callback: function($$v) {
                                                    _vm.winningCriteria = $$v
                                                  },
                                                  expression: "winningCriteria"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-divider", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.showSplits &&
                                      _vm.$_splitMixin_isSplit,
                                    expression:
                                      "showSplits && $_splitMixin_isSplit"
                                  }
                                ]
                              }),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSplits,
                                      expression: "showSplits"
                                    }
                                  ]
                                },
                                [
                                  _vm.splitMixin_expanded.length > 0 &&
                                  _vm.scrollSplitIndex !== -1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "fab-container",
                                          style: {
                                            right:
                                              (_vm.chatDrawer ? 420 : 0) + "px"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-speed-dial",
                                            {
                                              attrs: {
                                                top: "",
                                                right: "",
                                                direction: "bottom",
                                                transition: "slide-y-transition"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                _vm
                                                                                  .$_splitMixin_splitColors[
                                                                                  _vm
                                                                                    .scrollSplitIndex
                                                                                ],
                                                                              large:
                                                                                "",
                                                                              dark:
                                                                                "",
                                                                              fab:
                                                                                ""
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.splitActionsFab,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.splitActionsFab = $$v
                                                                              },
                                                                              expression:
                                                                                "splitActionsFab"
                                                                            }
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm.splitActionsFab
                                                                            ? _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                              mdi-close\n                            "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-h5"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                              " +
                                                                                      _vm._s(
                                                                                        _vm.$_splitMixin_indexToChar(
                                                                                          _vm.scrollSplitIndex
                                                                                        )
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  )
                                                                                ]
                                                                              )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              false,
                                                              1698524814
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Split " +
                                                                  _vm._s(
                                                                    _vm.$_splitMixin_indexToChar(
                                                                      _vm.scrollSplitIndex
                                                                    )
                                                                  ) +
                                                                  " Actions"
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                false,
                                                2144349296
                                              ),
                                              model: {
                                                value: _vm.splitActionsFab,
                                                callback: function($$v) {
                                                  _vm.splitActionsFab = $$v
                                                },
                                                expression: "splitActionsFab"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.$_splitMixin_isMaxSplits ||
                                                                      _vm.isLoading() ||
                                                                      _vm
                                                                        .splitMixin_splits
                                                                        .length ==
                                                                        4 ||
                                                                      _vm.isClicked,
                                                                    fab: "",
                                                                    small: "",
                                                                    color:
                                                                      "info"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.copySplit()
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-content-copy"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1808948877
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Copy Split")
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm
                                                                        .splitMixin_splits
                                                                        .length <=
                                                                        1 ||
                                                                      _vm.isLoading(),
                                                                    fab: "",
                                                                    small: "",
                                                                    color:
                                                                      "error"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeSplit()
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-delete"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3075146899
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Remove Split")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-expand-transition",
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        {
                                          attrs: { multiple: "" },
                                          model: {
                                            value: _vm.splitMixin_expanded,
                                            callback: function($$v) {
                                              _vm.splitMixin_expanded = $$v
                                            },
                                            expression: "splitMixin_expanded"
                                          }
                                        },
                                        _vm._l(_vm.splitMixin_splits, function(
                                          item,
                                          i
                                        ) {
                                          return _c(
                                            "v-expansion-panel",
                                            {
                                              key: "panel-" + i,
                                              ref: "splitPanel",
                                              refInFor: true
                                            },
                                            [
                                              _c(
                                                "v-expansion-panel-header",
                                                {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "actions",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    _vm
                                                                      .$_splitMixin_splitColors[
                                                                      i
                                                                    ]
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            $expand\n                          "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _vm.$_splitMixin_isSplit
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                sm: "3",
                                                                md: "2"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-progress-linear",
                                                                {
                                                                  attrs: {
                                                                    striped: "",
                                                                    height:
                                                                      "20",
                                                                    value:
                                                                      item.distribution,
                                                                    color:
                                                                      _vm
                                                                        .$_splitMixin_splitColors[
                                                                        i
                                                                      ]
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var value =
                                                                            ref.value
                                                                          return [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.distribution
                                                                                  ) +
                                                                                    "%"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.$_splitMixin_isSplit
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                                sm: "9",
                                                                md: "10"
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Split " +
                                                                    _vm._s(
                                                                      _vm.$_splitMixin_indexToChar(
                                                                        i
                                                                      )
                                                                    )
                                                                )
                                                              ]),
                                                              _c("v-slider", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value: !_vm.splitMixin_expanded.includes(
                                                                      i
                                                                    ),
                                                                    expression:
                                                                      "!splitMixin_expanded.includes(i)"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "ml-4",
                                                                style:
                                                                  "width: " +
                                                                  (item.distribution +
                                                                    _vm.$_splitMixin_winningDistribution) +
                                                                  "%",
                                                                attrs: {
                                                                  "thumb-label":
                                                                    "always",
                                                                  color:
                                                                    _vm
                                                                      .$_splitMixin_splitColors[
                                                                      i
                                                                    ],
                                                                  "thumb-color":
                                                                    _vm
                                                                      .$_splitMixin_splitColors[
                                                                      i
                                                                    ],
                                                                  "track-color":
                                                                    _vm.$_splitMixin_winningColor,
                                                                  min: "0",
                                                                  max:
                                                                    item.distribution +
                                                                    _vm.$_splitMixin_winningDistribution,
                                                                  "hide-details":
                                                                    ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                  },
                                                                  end: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.focusedSplit = null
                                                                    _vm.setSplitValue(
                                                                      i,
                                                                      "distribution"
                                                                    )
                                                                  },
                                                                  focus: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.focusedSplit = i
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.distribution,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "distribution",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.distribution"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-expansion-panel-content",
                                                {
                                                  directives: [
                                                    {
                                                      name: "intersect",
                                                      rawName:
                                                        "v-intersect.quiet",
                                                      value: {
                                                        handler:
                                                          _vm.onIntersect,
                                                        options: {
                                                          threshold: [
                                                            0.0,
                                                            0.05,
                                                            0.1,
                                                            0.15,
                                                            0.2,
                                                            0.25,
                                                            0.3,
                                                            0.35,
                                                            0.4,
                                                            0.45,
                                                            0.5,
                                                            0.55,
                                                            0.6,
                                                            0.65,
                                                            0.7,
                                                            0.75,
                                                            0.8,
                                                            0.85,
                                                            0.9,
                                                            0.95,
                                                            1.0
                                                          ]
                                                        }
                                                      },
                                                      expression:
                                                        "{\n                          handler: onIntersect,\n                          options: {\n                            threshold: [0.0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0]\n                          }\n                        }",
                                                      modifiers: { quiet: true }
                                                    }
                                                  ],
                                                  class: _vm.$_splitMixin_indexToChar(
                                                    i
                                                  )
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _vm.$_splitMixin_isSplit
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "3",
                                                                md: "2"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    "error-messages": _vm.distributionErrors(
                                                                      i
                                                                    ),
                                                                    dense: "",
                                                                    color:
                                                                      _vm
                                                                        .$_splitMixin_splitColors[
                                                                        i
                                                                      ],
                                                                    type:
                                                                      "number",
                                                                    label:
                                                                      _vm.$_splitMixin_indexToChar(
                                                                        i
                                                                      ) + " %",
                                                                    "append-icon":
                                                                      "mdi-percent",
                                                                    "hide-details":
                                                                      "",
                                                                    min: "0",
                                                                    max:
                                                                      item.distribution +
                                                                      _vm.$_splitMixin_winningDistribution
                                                                  },
                                                                  on: {
                                                                    blur: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.focusedSplit = null
                                                                      _vm.setSplitValue(
                                                                        i,
                                                                        "distribution"
                                                                      )
                                                                    },
                                                                    focus: function(
                                                                      $event
                                                                    ) {
                                                                      _vm.focusedSplit = i
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.distribution,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "distribution",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "item.distribution"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.$_splitMixin_isSplit
                                                        ? _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                                sm: "9",
                                                                md: "10"
                                                              }
                                                            },
                                                            [
                                                              _c("v-slider", {
                                                                style:
                                                                  "width: " +
                                                                  (item.distribution +
                                                                    _vm.$_splitMixin_winningDistribution) +
                                                                  "%",
                                                                attrs: {
                                                                  "thumb-label":
                                                                    "always",
                                                                  color:
                                                                    _vm
                                                                      .$_splitMixin_splitColors[
                                                                      i
                                                                    ],
                                                                  "thumb-color":
                                                                    _vm
                                                                      .$_splitMixin_splitColors[
                                                                      i
                                                                    ],
                                                                  "track-color":
                                                                    _vm.$_splitMixin_winningColor,
                                                                  min: "0",
                                                                  max:
                                                                    item.distribution +
                                                                    _vm.$_splitMixin_winningDistribution,
                                                                  "hide-details":
                                                                    ""
                                                                },
                                                                on: {
                                                                  end: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.focusedSplit = null
                                                                    _vm.setSplitValue(
                                                                      i,
                                                                      "distribution"
                                                                    )
                                                                  },
                                                                  focus: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.focusedSplit = i
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.distribution,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "distribution",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.distribution"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "6",
                                                            lg: "4"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              "error-messages": _vm.fromNameErrors(
                                                                i
                                                              ),
                                                              dense: "",
                                                              color:
                                                                _vm
                                                                  .$_splitMixin_splitColors[
                                                                  i
                                                                ],
                                                              label: "From Name"
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                return _vm.$v.splitMixin_splits.$each[
                                                                  i
                                                                ].fromName.$touch()
                                                              },
                                                              blur: function(
                                                                $event
                                                              ) {
                                                                _vm.$v.splitMixin_splits.$each[
                                                                  i
                                                                ].fromName.$touch()
                                                                _vm.focusedSplit = null
                                                                _vm.setSplitValue(
                                                                  i,
                                                                  "fromName"
                                                                )
                                                              },
                                                              focus: function(
                                                                $event
                                                              ) {
                                                                _vm.focusedSplit = i
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item.fromName,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "fromName",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.fromName"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "6",
                                                            lg: "5"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              "error-messages": _vm.fromAddressErrors(
                                                                i
                                                              ),
                                                              dense: "",
                                                              color:
                                                                _vm
                                                                  .$_splitMixin_splitColors[
                                                                  i
                                                                ],
                                                              label:
                                                                "From Address",
                                                              suffix:
                                                                "" +
                                                                (_vm.isSharedDomain
                                                                  ? "@med.email"
                                                                  : item.replyAddress)
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                return _vm.$v.splitMixin_splits.$each[
                                                                  i
                                                                ].fromAddress.$touch()
                                                              },
                                                              blur: function(
                                                                $event
                                                              ) {
                                                                _vm.$v.splitMixin_splits.$each[
                                                                  i
                                                                ].fromAddress.$touch()
                                                                _vm.focusedSplit = null
                                                                _vm.setSplitValue(
                                                                  i,
                                                                  "fromAddress"
                                                                )
                                                              },
                                                              focus: function(
                                                                $event
                                                              ) {
                                                                _vm.focusedSplit = i
                                                              }
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                _vm.isSharedDomain
                                                                  ? {
                                                                      key:
                                                                        "append",
                                                                      fn: function() {
                                                                        return [
                                                                          _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs: {
                                                                                top:
                                                                                  ""
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function(
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      return [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          _vm._g(
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  _vm
                                                                                                    .$_splitMixin_splitColors[
                                                                                                    i
                                                                                                  ]
                                                                                              }
                                                                                            },
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                      mdi-help-circle-outline\n                                    "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                null,
                                                                                true
                                                                              )
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Broadcast could be sent off shared domains: "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.domains
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      },
                                                                      proxy: true
                                                                    }
                                                                  : null
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                item.fromAddress,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "fromAddress",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.fromAddress"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c("v-textarea", {
                                                            attrs: {
                                                              "error-messages": _vm.subjectErrors(
                                                                i
                                                              ),
                                                              dense: "",
                                                              color:
                                                                _vm
                                                                  .$_splitMixin_splitColors[
                                                                  i
                                                                ],
                                                              counter: "255",
                                                              label:
                                                                "Subject " +
                                                                _vm.$_splitMixin_indexToChar(
                                                                  i
                                                                ),
                                                              "auto-grow": "",
                                                              "no-resize": "",
                                                              rows: "1",
                                                              "data-wsc-autocreate":
                                                                "true",
                                                              "data-wsc-lang":
                                                                "en_US"
                                                            },
                                                            on: {
                                                              keydown: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                $event.preventDefault()
                                                              },
                                                              change: function(
                                                                $event
                                                              ) {
                                                                item.subject = _vm.removeEndLine(
                                                                  item.subject
                                                                )
                                                              },
                                                              input: function(
                                                                $event
                                                              ) {
                                                                _vm.$v.splitMixin_splits.$each[
                                                                  i
                                                                ].subject.$touch()
                                                                _vm.disableSLA()
                                                              },
                                                              blur: function(
                                                                $event
                                                              ) {
                                                                _vm.$v.splitMixin_splits.$each[
                                                                  i
                                                                ].subject.$touch()
                                                                _vm.focusedSplit = null
                                                                _vm.setSplitValue(
                                                                  i,
                                                                  "subject"
                                                                )
                                                              },
                                                              focus: function(
                                                                $event
                                                              ) {
                                                                _vm.focusedSplit = i
                                                              }
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "append-outer",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            top:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var onTooltip =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "mb-1",
                                                                                          attrs: {
                                                                                            disabled: !item.subject,
                                                                                            "x-small":
                                                                                              "",
                                                                                            fab:
                                                                                              "",
                                                                                            color:
                                                                                              _vm
                                                                                                .$_splitMixin_splitColors[
                                                                                                i
                                                                                              ]
                                                                                          },
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.validateSubjectLine(
                                                                                                item.subject,
                                                                                                item.id,
                                                                                                i
                                                                                              )
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        onTooltip
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                        mdi-lightbulb-on-outline\n                                      "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Analyze Subject Line"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                item.subject,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "subject",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.subject"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm
                                                                      .showSubjectLineData
                                                                      .index ===
                                                                      i &&
                                                                    _vm.isSubjLineClicked,
                                                                  expression:
                                                                    "showSubjectLineData.index === i && isSubjLineClicked"
                                                                }
                                                              ],
                                                              ref: "subjLine",
                                                              refInFor: true,
                                                              staticClass:
                                                                "mr-7",
                                                              attrs: {
                                                                outlined: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-card-subtitle",
                                                                {
                                                                  staticClass:
                                                                    "text-h5"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                Your Subject Line Score:\n                              "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-card-title",
                                                                {
                                                                  staticClass:
                                                                    "ml-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    '\n                                "' +
                                                                      _vm._s(
                                                                        _vm
                                                                          .subjectLineResponse
                                                                          .subject
                                                                      ) +
                                                                      '"\n                              '
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-card-text",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      attrs: {
                                                                        justify:
                                                                          "space-between"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          staticClass:
                                                                            "text-left d-flex flex-wrap",
                                                                          attrs: {
                                                                            cols:
                                                                              "6",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-item",
                                                                            {
                                                                              attrs: {
                                                                                "three-line":
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mb-2"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-h5 font-weight-bold text-center mb-5 mt-3"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "Your score is "
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-h2 font-weight-bold text-center mb-5 mt-3",
                                                                                              style:
                                                                                                "color: " +
                                                                                                _vm.getScoreColor(
                                                                                                  _vm
                                                                                                    .subjectLineResponse
                                                                                                    .score
                                                                                                )
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                              " +
                                                                                                  _vm._s(
                                                                                                    _vm
                                                                                                      .subjectLineResponse
                                                                                                      .score >=
                                                                                                      50
                                                                                                      ? _vm
                                                                                                          .subjectLineResponse
                                                                                                          .score
                                                                                                      : 50
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-h5 font-weight-bold text-center mb-5 mt-3"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " out of 100"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "v-progress-linear",
                                                                                        {
                                                                                          attrs: {
                                                                                            color: _vm.getScoreColor(
                                                                                              _vm
                                                                                                .subjectLineResponse
                                                                                                .score
                                                                                            ),
                                                                                            height:
                                                                                              "13",
                                                                                            value:
                                                                                              "20",
                                                                                            valuemax:
                                                                                              "100",
                                                                                            striped:
                                                                                              ""
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm
                                                                                                .subjectLineResponse
                                                                                                .score,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.subjectLineResponse,
                                                                                                "score",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                            expression:
                                                                                              "subjectLineResponse.score"
                                                                                          }
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "v-list-item-subtitle",
                                                                                        {
                                                                                          staticClass:
                                                                                            "mt-3"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                            " +
                                                                                              _vm._s(
                                                                                                _vm
                                                                                                  .subjectLineResponse
                                                                                                  .message
                                                                                              ) +
                                                                                              "\n                                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        {
                                                                          attrs: {
                                                                            cols:
                                                                              "6",
                                                                            sm:
                                                                              "6"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-divider"
                                                                          ),
                                                                          _c(
                                                                            "v-list-item",
                                                                            [
                                                                              _c(
                                                                                "v-list-item-content",
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item",
                                                                                    {
                                                                                      directives: [
                                                                                        {
                                                                                          name:
                                                                                            "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            _vm
                                                                                              .riskWordList
                                                                                              .length >
                                                                                            0,
                                                                                          expression:
                                                                                            "riskWordList.length > 0"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-card-subtitle",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-h6 pb-1"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                            The following word(s): "
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "font-weight-bold font-italic"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.riskWordList
                                                                                                    .toString()
                                                                                                    .replace(
                                                                                                      /\[|\]/g,
                                                                                                      ""
                                                                                                    )
                                                                                                    .replace(
                                                                                                      /,/g,
                                                                                                      ", "
                                                                                                    )
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " are impacting your final score.\n                                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-list-item",
                                                                                    {
                                                                                      directives: [
                                                                                        {
                                                                                          name:
                                                                                            "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            _vm
                                                                                              .pairWordList
                                                                                              .length >
                                                                                            0,
                                                                                          expression:
                                                                                            "pairWordList.length > 0"
                                                                                        }
                                                                                      ]
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-card-subtitle",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-h6 pb-1"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                            The following phrases: "
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "font-weight-bold font-italic"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.pairWordList
                                                                                                    .toString()
                                                                                                    .replace(
                                                                                                      /\[|\]/g,
                                                                                                      ""
                                                                                                    )
                                                                                                    .replace(
                                                                                                      /,/g,
                                                                                                      ", "
                                                                                                    )
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " are impacting your final score.\n                                          "
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._l(
                                                                                    _vm.summaryMessage,
                                                                                    function(
                                                                                      message,
                                                                                      j
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-list-item",
                                                                                        {
                                                                                          key: j,
                                                                                          staticStyle: {
                                                                                            "justify-content":
                                                                                              "left"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-card-subtitle",
                                                                                            {
                                                                                              staticClass:
                                                                                                "text-h6 pb-1"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                            " +
                                                                                                  _vm._s(
                                                                                                    message
                                                                                                  ) +
                                                                                                  "\n                                          "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                2
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-card-actions",
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "ml-5 mb-2",
                                                                      attrs: {
                                                                        color:
                                                                          "primary",
                                                                        href:
                                                                          "https://insp01.blob.core.windows.net/public/noww_docs/Subject_Line_Best_Practices.pdf",
                                                                        target:
                                                                          "_blank"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                  Explore Best Practices\n                                  "
                                                                      ),
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "pl-3",
                                                                          attrs: {
                                                                            size:
                                                                              "18"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    mdi-open-in-new\n                                  "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      staticClass:
                                                                        "ml-5 mb-2",
                                                                      attrs: {
                                                                        color:
                                                                          "tertiary"
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.closeSubjLineDialog
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                  close\n                                "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-spacer"
                                                                  ),
                                                                  _c(
                                                                    "v-card-subtitle",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            _vm
                                                                              .subjectLineResponse
                                                                              .score <=
                                                                            50,
                                                                          expression:
                                                                            "subjectLineResponse.score <= 50"
                                                                        }
                                                                      ],
                                                                      staticClass:
                                                                        "mt-1",
                                                                      staticStyle: {
                                                                        color:
                                                                          "red"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                  *Contact your CSM for authorization if you would like to proceed with this subject line.\n                                "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-fade-transition",
                                                                [
                                                                  _vm.isSlaDisabled
                                                                    ? _c(
                                                                        "v-overlay",
                                                                        {
                                                                          attrs: {
                                                                            absolute:
                                                                              "",
                                                                            opacity:
                                                                              ".70",
                                                                            color:
                                                                              "#FFFFFF"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "primary"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.validateSubjectLine(
                                                                                    item.subject,
                                                                                    item.id,
                                                                                    i
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                    Re-run analysis\n                                  "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12"
                                                          }
                                                        },
                                                        [
                                                          _c("v-textarea", {
                                                            attrs: {
                                                              "error-messages": _vm.previewTextErrors(
                                                                i
                                                              ),
                                                              dense: "",
                                                              color:
                                                                _vm
                                                                  .$_splitMixin_splitColors[
                                                                  i
                                                                ],
                                                              counter: "140",
                                                              label:
                                                                "Preview Text",
                                                              "auto-grow": "",
                                                              clearable: "",
                                                              "no-resize": "",
                                                              rows: "1",
                                                              "data-wsc-autocreate":
                                                                "true",
                                                              "data-wsc-lang":
                                                                "en_US"
                                                            },
                                                            on: {
                                                              keydown: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  !$event.type.indexOf(
                                                                    "key"
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter",
                                                                    13,
                                                                    $event.key,
                                                                    "Enter"
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                $event.preventDefault()
                                                              },
                                                              change: function(
                                                                $event
                                                              ) {
                                                                item.previewText = _vm.removeEndLine(
                                                                  item.previewText
                                                                )
                                                              },
                                                              input: function(
                                                                $event
                                                              ) {
                                                                return _vm.$v.splitMixin_splits.$each[
                                                                  i
                                                                ].previewText.$touch()
                                                              },
                                                              blur: function(
                                                                $event
                                                              ) {
                                                                _vm.$v.splitMixin_splits.$each[
                                                                  i
                                                                ].previewText.$touch()
                                                                _vm.focusedSplit = null
                                                                _vm.setSplitValue(
                                                                  i,
                                                                  "previewText"
                                                                )
                                                              },
                                                              focus: function(
                                                                $event
                                                              ) {
                                                                _vm.focusedSplit = i
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item.previewText,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "previewText",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.previewText"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-center",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-dialog",
                                                            {
                                                              attrs: {
                                                                persistent: "",
                                                                scrollable: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "success",
                                                                                disabled: _vm.isLoading()
                                                                              }
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  left:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                    mdi-cloud-download\n                                  "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                  Choose Creative from Content\n                                "
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  item.clChooseDialog,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "clChooseDialog",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.clChooseDialog"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                [
                                                                  _c(
                                                                    "v-card-title",
                                                                    {
                                                                      staticClass:
                                                                        "text-h6 grey lighten-2",
                                                                      attrs: {
                                                                        "primary-title":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                  Content Selection\n                                "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-card-text",
                                                                    {
                                                                      staticClass:
                                                                        "black--text pt-5"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold red--text"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    By selecting a new creative, you will lose any content currently in your editor and text version of creative.\n                                  "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-tabs",
                                                                        {
                                                                          attrs: {
                                                                            centered:
                                                                              "",
                                                                            "fixed-tabs":
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.clTab,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.clTab = $$v
                                                                            },
                                                                            expression:
                                                                              "clTab"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-tab",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                      Content Library\n                                    "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-tab",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                      Template Library\n                                    "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-tabs-items",
                                                                        {
                                                                          model: {
                                                                            value:
                                                                              _vm.clTab,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.clTab = $$v
                                                                            },
                                                                            expression:
                                                                              "clTab"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-tab-item",
                                                                            [
                                                                              _c(
                                                                                "ContentLibraryMain",
                                                                                {
                                                                                  ref:
                                                                                    "clChooseContent",
                                                                                  refInFor: true,
                                                                                  attrs: {
                                                                                    root:
                                                                                      _vm.clHtmlRoot,
                                                                                    "no-rename-folder":
                                                                                      "",
                                                                                    "no-delete-folder":
                                                                                      "",
                                                                                    "no-create-folder":
                                                                                      "",
                                                                                    "no-download-folder":
                                                                                      "",
                                                                                    "no-upload-files":
                                                                                      "",
                                                                                    "no-new-creative":
                                                                                      "",
                                                                                    "no-delete-checked":
                                                                                      "",
                                                                                    "no-download-checked":
                                                                                      "",
                                                                                    "no-details-selected":
                                                                                      "",
                                                                                    checkable: false
                                                                                  },
                                                                                  on: {
                                                                                    selected:
                                                                                      _vm.clHtmlSelected,
                                                                                    unselected: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.clHtmlUnselected()
                                                                                    }
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-tab-item",
                                                                            [
                                                                              _c(
                                                                                "TemplateLibrary",
                                                                                {
                                                                                  ref:
                                                                                    "templateLibrary",
                                                                                  refInFor: true,
                                                                                  on: {
                                                                                    change:
                                                                                      _vm.templateChange
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                  _c(
                                                                    "v-card-actions",
                                                                    [
                                                                      _c(
                                                                        "v-spacer"
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "tertiary",
                                                                            disabled:
                                                                              item.clHtmlCopying
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.closeCLChooseDialog(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    Cancel\n                                  "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm.clTab ===
                                                                      0
                                                                        ? _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                disabled: !_vm.clHtmlSelectedItem,
                                                                                loading:
                                                                                  item.clHtmlCopying,
                                                                                color:
                                                                                  "success"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.copyCLHtmlToStage(
                                                                                    item,
                                                                                    i
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                    Select\n                                  "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm.clTab ===
                                                                          1
                                                                        ? _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                loading:
                                                                                  item.clHtmlCopying,
                                                                                color:
                                                                                  "success",
                                                                                disabled:
                                                                                  _vm.clSelectedTemplate ===
                                                                                  null
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.copyCLTemplateToStage(
                                                                                    item,
                                                                                    i
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                    Select\n                                  "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c("v-divider", {
                                                            staticClass:
                                                              "mx-4 d-none d-sm-flex",
                                                            attrs: {
                                                              vertical: ""
                                                            }
                                                          }),
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-offset": "",
                                                                origin: "right",
                                                                transition:
                                                                  "slide-x-transition",
                                                                "close-on-content-click": false,
                                                                "close-on-click": false
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var onMenu =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "success",
                                                                                disabled: _vm.isLoading()
                                                                              }
                                                                            },
                                                                            Object.assign(
                                                                              {},
                                                                              onMenu
                                                                            )
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  left:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                    mdi-text-box-plus\n                                  "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                  Upload New\n                                "
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .uploadCreative[
                                                                    i
                                                                  ].menu,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .uploadCreative[
                                                                      i
                                                                    ],
                                                                    "menu",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "uploadCreative[i].menu"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      _vm.menuWidth
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card-text",
                                                                    {
                                                                      staticClass:
                                                                        "px-5 py-2"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-file-input",
                                                                        {
                                                                          attrs: {
                                                                            rules:
                                                                              _vm.uploadCreativeRules,
                                                                            accept:
                                                                              _vm.acceptedFileExtensions,
                                                                            "show-size": 1000,
                                                                            "error-messages":
                                                                              _vm
                                                                                .uploadCreative[
                                                                                i
                                                                              ]
                                                                                .errors,
                                                                            counter:
                                                                              "",
                                                                            color:
                                                                              "secondary",
                                                                            label:
                                                                              "Upload File"
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.uploadCreative[
                                                                                i
                                                                              ].errors = []
                                                                            },
                                                                            "click:clear": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.uploadCreative[
                                                                                i
                                                                              ].file = null
                                                                            }
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "selection",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var text =
                                                                                    ref.text
                                                                                  return [
                                                                                    _c(
                                                                                      "v-chip",
                                                                                      {
                                                                                        attrs: {
                                                                                          color:
                                                                                            "secondary",
                                                                                          dark:
                                                                                            "",
                                                                                          label:
                                                                                            "",
                                                                                          small:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                        " +
                                                                                            _vm._s(
                                                                                              text
                                                                                            ) +
                                                                                            "\n                                      "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .uploadCreative[
                                                                                i
                                                                              ]
                                                                                .file,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .uploadCreative[
                                                                                  i
                                                                                ],
                                                                                "file",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "uploadCreative[i].file"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "font-italic pl-7 inline-block"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    Valid file extensions: " +
                                                                              _vm._s(
                                                                                _vm.acceptedFileExtensions
                                                                              ) +
                                                                              "\n                                  "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold pl-7 red--text inline-block"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    By uploading a new creative, you will lose any content currently in your editor and text version of creative.\n                                  "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                  _c(
                                                                    "v-card-actions",
                                                                    [
                                                                      _c(
                                                                        "v-spacer"
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "tertiary",
                                                                            disabled:
                                                                              _vm
                                                                                .uploadCreative[
                                                                                i
                                                                              ]
                                                                                .uploading
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.closeUploadCreative(
                                                                                item,
                                                                                i
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    Cancel\n                                  "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "success",
                                                                            loading:
                                                                              _vm
                                                                                .uploadCreative[
                                                                                i
                                                                              ]
                                                                                .uploading ||
                                                                              _vm.isLoading()
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.uploadCreativeSave(
                                                                                item,
                                                                                i
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    Upload\n                                  "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c("v-divider", {
                                                            staticClass:
                                                              "mx-4 d-none d-sm-flex",
                                                            attrs: {
                                                              vertical: ""
                                                            }
                                                          }),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var onTooltip =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "mx-1",
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                color:
                                                                                  "success"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.downloadSplit(
                                                                                    item.id
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            onTooltip
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-file-download"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Download Creative"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var onTooltip =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "mx-1",
                                                                              attrs: {
                                                                                disabled:
                                                                                  _vm.isLoading() ||
                                                                                  !_vm
                                                                                    .editorUnsaved[
                                                                                    i
                                                                                  ] ||
                                                                                  _vm
                                                                                    .inCodeView[
                                                                                    i
                                                                                  ],
                                                                                icon:
                                                                                  "",
                                                                                color:
                                                                                  "success"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.saveHtml(
                                                                                    i
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            Object.assign(
                                                                              {},
                                                                              onTooltip
                                                                            )
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-content-save"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Save Html"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-dialog",
                                                            {
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var onDialog =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              top:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var onTooltip =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          {
                                                                                            staticClass:
                                                                                              "mx-1",
                                                                                            attrs: {
                                                                                              disabled: _vm.isLoading(),
                                                                                              icon:
                                                                                                "",
                                                                                              color:
                                                                                                "info"
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.openOptOutPreviewDialog()
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          Object.assign(
                                                                                            {},
                                                                                            onTooltip,
                                                                                            onDialog
                                                                                          )
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "mdi-text-box"
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Preview Opt Out"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.optOutPreviewDialog,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.optOutPreviewDialog = $$v
                                                                },
                                                                expression:
                                                                  "optOutPreviewDialog"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                [
                                                                  _c(
                                                                    "v-card-title",
                                                                    {
                                                                      staticClass:
                                                                        "text-h6 grey lighten-2",
                                                                      attrs: {
                                                                        "primary-title":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                  Opt Out Preview\n                                "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-card-text",
                                                                    {
                                                                      staticClass:
                                                                        "black--text pt-5"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "OptOutPreview",
                                                                            {
                                                                              attrs: {
                                                                                "opt-out-id":
                                                                                  _vm.optOutId,
                                                                                "edit-mode":
                                                                                  _vm.optOutEditable,
                                                                                "footer-margin-bottom": 54
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                  _c(
                                                                    "v-card-actions",
                                                                    [
                                                                      _c(
                                                                        "v-spacer"
                                                                      ),
                                                                      !_vm.optOutEditable
                                                                        ? _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "tertiary"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.closeOptOutPreviewDialog()
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                    Cancel\n                                  "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "success"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.closeOptOutPreviewDialog()
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                    Save\n                                  "
                                                                              )
                                                                            ]
                                                                          )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var onTooltip =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "mx-1",
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                color: item.devicePreview
                                                                                  ? "secondary"
                                                                                  : "info"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.toggleEditorMenu(
                                                                                    item,
                                                                                    "devicePreview",
                                                                                    !item.devicePreview
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            Object.assign(
                                                                              {},
                                                                              onTooltip
                                                                            )
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-" +
                                                                                    _vm._s(
                                                                                      item.devicePreview
                                                                                        ? "close"
                                                                                        : "tablet-cellphone"
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.devicePreview
                                                                      ? "Close "
                                                                      : ""
                                                                  ) +
                                                                    "Device Preview"
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var onTooltip =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "mx-1",
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                color: item.dragAndDrop
                                                                                  ? "secondary"
                                                                                  : "info"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.toggleEditorMenu(
                                                                                    item,
                                                                                    "dragAndDrop",
                                                                                    !item.dragAndDrop
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            Object.assign(
                                                                              {},
                                                                              onTooltip
                                                                            )
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-" +
                                                                                    _vm._s(
                                                                                      item.dragAndDrop
                                                                                        ? "close"
                                                                                        : "gesture-swipe"
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.dragAndDrop
                                                                      ? "Close "
                                                                      : ""
                                                                  ) +
                                                                    "Drag & Drop Menu"
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "12",
                                                            sm: "12",
                                                            lg:
                                                              (item.devicePreview &&
                                                                (!item.deviceWidth ||
                                                                  item.deviceWidth <
                                                                    600)) ||
                                                              item.dragAndDrop
                                                                ? "8"
                                                                : "12"
                                                          }
                                                        },
                                                        [
                                                          _vm._l(
                                                            item.htmlErrors,
                                                            function(
                                                              htmlError,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "p",
                                                                {
                                                                  key:
                                                                    "htmlErrors-" +
                                                                    index,
                                                                  staticClass:
                                                                    "red--text font-weight-bold text-left"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      attrs: {
                                                                        color:
                                                                          "error"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                mdi-alert-circle-outline\n                              "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        htmlError
                                                                      ) +
                                                                      "\n                            "
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          _vm._l(
                                                            item.htmlWarnings,
                                                            function(
                                                              htmlWarning,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "p",
                                                                {
                                                                  key:
                                                                    "htmlWarnings-" +
                                                                    index,
                                                                  staticClass:
                                                                    "amber--text font-weight-bold text-left"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-1",
                                                                      attrs: {
                                                                        color:
                                                                          "amber"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                mdi-alert-outline\n                              "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        htmlWarning
                                                                      ) +
                                                                      "\n                            "
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          _vm.isInit
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c("froala", {
                                                                    ref:
                                                                      "froalaEditor",
                                                                    refInFor: true,
                                                                    attrs: {
                                                                      config: _vm.froalaConfig(
                                                                        i
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item.html,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "html",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "item.html"
                                                                    }
                                                                  }),
                                                                  _c(
                                                                    "ImageManager",
                                                                    {
                                                                      attrs: {
                                                                        source:
                                                                          _vm.imageRoot,
                                                                        destination:
                                                                          item.azImageFolder
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "v-skeleton-loader",
                                                                {
                                                                  attrs: {
                                                                    type:
                                                                      "article@3"
                                                                  }
                                                                }
                                                              )
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.devicePreview,
                                                              expression:
                                                                "item.devicePreview"
                                                            }
                                                          ],
                                                          attrs: {
                                                            cols: "12",
                                                            lg:
                                                              !item.deviceWidth ||
                                                              item.deviceWidth <
                                                                600
                                                                ? "4"
                                                                : "12"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "SplitDevicePreview",
                                                            {
                                                              ref:
                                                                "splitDevicePreview",
                                                              refInFor: true,
                                                              attrs: {
                                                                html: item.html,
                                                                "text-accessibility":
                                                                  item.textAccessibility,
                                                                "no-desktop": ""
                                                              },
                                                              on: {
                                                                change: function(
                                                                  value
                                                                ) {
                                                                  return _vm.changedDevice(
                                                                    value,
                                                                    item,
                                                                    i
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.dragAndDrop,
                                                              expression:
                                                                "item.dragAndDrop"
                                                            }
                                                          ],
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "4"
                                                          }
                                                        },
                                                        [
                                                          _c("DragAndDrop", {
                                                            model: {
                                                              value:
                                                                item.dragAndDropExpanded,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "dragAndDropExpanded",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.dragAndDropExpanded"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-center",
                                                          attrs: { cols: "12" }
                                                        },
                                                        [
                                                          !item.textAccessibility
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "success",
                                                                    disabled: _vm.isLoading(),
                                                                    loading:
                                                                      _vm
                                                                        .uploadCreative[
                                                                        i
                                                                      ]
                                                                        .parsingText
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.parseCreativeTextSave(
                                                                        item,
                                                                        i
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        left: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                mdi-book-open-variant\n                              "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                              Parse Creative Text\n                            "
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "v-menu",
                                                                {
                                                                  attrs: {
                                                                    right: "",
                                                                    "x-offset":
                                                                      "",
                                                                    origin:
                                                                      "right",
                                                                    transition:
                                                                      "slide-x-transition",
                                                                    "close-on-content-click": false,
                                                                    "close-on-click": false
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var onMenu =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                {
                                                                                  attrs: {
                                                                                    color:
                                                                                      "success",
                                                                                    disabled: _vm.isLoading()
                                                                                  }
                                                                                },
                                                                                Object.assign(
                                                                                  {},
                                                                                  onMenu
                                                                                )
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs: {
                                                                                      left:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                    mdi-book-open-variant\n                                  "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  "\n                                  Parse Creative Text\n                                "
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .uploadCreative[
                                                                        i
                                                                      ]
                                                                        .textMenu,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .uploadCreative[
                                                                          i
                                                                        ],
                                                                        "textMenu",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "uploadCreative[i].textMenu"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    {
                                                                      attrs: {
                                                                        width:
                                                                          _vm.menuWidth
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card-text",
                                                                        {
                                                                          staticClass:
                                                                            "px-5 py-4"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-bold pl-7 red--text inline-block"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                    By parsing the text out of your creative, you will lose any content currently in the text version of your creative.\n                                  "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-divider"
                                                                      ),
                                                                      _c(
                                                                        "v-card-actions",
                                                                        [
                                                                          _c(
                                                                            "v-spacer"
                                                                          ),
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "tertiary",
                                                                                disabled:
                                                                                  _vm
                                                                                    .uploadCreative[
                                                                                    i
                                                                                  ]
                                                                                    .parsingText
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.closeParseCreativeText(
                                                                                    item,
                                                                                    i
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                    Cancel\n                                  "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "success",
                                                                                loading:
                                                                                  _vm
                                                                                    .uploadCreative[
                                                                                    i
                                                                                  ]
                                                                                    .parsingText ||
                                                                                  _vm.isLoading()
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.parseCreativeTextSave(
                                                                                    item,
                                                                                    i
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                    Ok\n                                  "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                          _c("v-divider", {
                                                            staticClass:
                                                              "mx-4 d-none d-sm-flex",
                                                            attrs: {
                                                              vertical: ""
                                                            }
                                                          }),
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-offset": "",
                                                                origin: "right",
                                                                transition:
                                                                  "slide-x-transition",
                                                                "close-on-content-click": false,
                                                                "close-on-click": false
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var onMenu =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              attrs: {
                                                                                color:
                                                                                  "success",
                                                                                disabled: _vm.isLoading()
                                                                              }
                                                                            },
                                                                            Object.assign(
                                                                              {},
                                                                              onMenu
                                                                            )
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs: {
                                                                                  left:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                    mdi-text-box-plus\n                                  "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                  Upload Text\n                                "
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .uploadCreative[
                                                                    i
                                                                  ].menu2,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .uploadCreative[
                                                                      i
                                                                    ],
                                                                    "menu2",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "uploadCreative[i].menu2"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      _vm.menuWidth
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card-text",
                                                                    {
                                                                      staticClass:
                                                                        "px-5 py-2"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-file-input",
                                                                        {
                                                                          attrs: {
                                                                            rules:
                                                                              _vm.uploadCreativeRules,
                                                                            accept:
                                                                              _vm.acceptedTextFileExtensions,
                                                                            "show-size": 1000,
                                                                            "error-messages":
                                                                              _vm
                                                                                .uploadCreative[
                                                                                i
                                                                              ]
                                                                                .errors2,
                                                                            counter:
                                                                              "",
                                                                            color:
                                                                              "secondary",
                                                                            label:
                                                                              "Upload File"
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.uploadCreative[
                                                                                i
                                                                              ].errors2 = []
                                                                            },
                                                                            "click:clear": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.uploadCreative[
                                                                                i
                                                                              ].file2 = null
                                                                            }
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "selection",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var text =
                                                                                    ref.text
                                                                                  return [
                                                                                    _c(
                                                                                      "v-chip",
                                                                                      {
                                                                                        attrs: {
                                                                                          color:
                                                                                            "secondary",
                                                                                          dark:
                                                                                            "",
                                                                                          label:
                                                                                            "",
                                                                                          small:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                        " +
                                                                                            _vm._s(
                                                                                              text
                                                                                            ) +
                                                                                            "\n                                      "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .uploadCreative[
                                                                                i
                                                                              ]
                                                                                .file2,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .uploadCreative[
                                                                                  i
                                                                                ],
                                                                                "file2",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "uploadCreative[i].file2"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "font-italic pl-7 inline-block"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    Valid file extensions: " +
                                                                              _vm._s(
                                                                                _vm.acceptedTextFileExtensions
                                                                              ) +
                                                                              "\n                                  "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "font-weight-bold pl-7 red--text inline-block"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    By uploading a new text version, you will lose any content currently in your text version of creative.\n                                  "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                  _c(
                                                                    "v-card-actions",
                                                                    [
                                                                      _c(
                                                                        "v-spacer"
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "tertiary",
                                                                            disabled:
                                                                              _vm
                                                                                .uploadCreative[
                                                                                i
                                                                              ]
                                                                                .uploading2
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.closeUploadText(
                                                                                item,
                                                                                i
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    Cancel\n                                  "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "success",
                                                                            loading:
                                                                              _vm
                                                                                .uploadCreative[
                                                                                i
                                                                              ]
                                                                                .uploading2 ||
                                                                              _vm.isLoading()
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.uploadTextSave(
                                                                                item,
                                                                                i
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    Upload\n                                  "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c("v-divider", {
                                                            staticClass:
                                                              "mx-4 d-none d-sm-flex",
                                                            attrs: {
                                                              vertical: ""
                                                            }
                                                          }),
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                                "x-offset": "",
                                                                origin: "right",
                                                                transition:
                                                                  "slide-x-transition"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var onMenu =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-tooltip",
                                                                          {
                                                                            attrs: {
                                                                              top:
                                                                                ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "activator",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var onTooltip =
                                                                                      ref.on
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          {
                                                                                            staticClass:
                                                                                              "mx-1 d-none d-sm-flex",
                                                                                            attrs: {
                                                                                              color:
                                                                                                "error",
                                                                                              icon:
                                                                                                "",
                                                                                              disabled:
                                                                                                _vm.isLoading() ||
                                                                                                !item.textAccessibility
                                                                                            }
                                                                                          },
                                                                                          Object.assign(
                                                                                            {},
                                                                                            onMenu,
                                                                                            onTooltip
                                                                                          )
                                                                                        ),
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                        mdi-text-box-minus\n                                      "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "Clear Text Version"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      _vm.menuWidth
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-card-text",
                                                                    {
                                                                      staticClass:
                                                                        "pa-5"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Are you sure you want to clear the text version?"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                  _c(
                                                                    "v-card-actions",
                                                                    [
                                                                      _c(
                                                                        "v-spacer"
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "tertiary",
                                                                            disabled: _vm.isLoading()
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    Cancel\n                                  "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "error",
                                                                            loading: _vm.isLoading()
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.clearTextAccessibility(
                                                                                i
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                    Yes\n                                  "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var onTooltip =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "mx-1 d-none d-sm-flex",
                                                                              attrs: {
                                                                                icon:
                                                                                  "",
                                                                                color:
                                                                                  "info"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.toggleTextMenu(
                                                                                    item,
                                                                                    "textMobileDevicePreview",
                                                                                    !item.textMobileDevicePreview
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            Object.assign(
                                                                              {},
                                                                              onTooltip
                                                                            )
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-" +
                                                                                    _vm._s(
                                                                                      item.textMobileDevicePreview
                                                                                        ? "monitor"
                                                                                        : "cellphone"
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.textMobileDevicePreview
                                                                      ? "Desktop"
                                                                      : "Phone"
                                                                  ) +
                                                                    " Device Preview"
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        justify: "center"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-center",
                                                          attrs: {
                                                            xl: item.textMobileDevicePreview
                                                              ? "4"
                                                              : "12",
                                                            lg: item.textMobileDevicePreview
                                                              ? "5"
                                                              : "12",
                                                            md: item.textMobileDevicePreview
                                                              ? "7"
                                                              : "12",
                                                            sm: item.textMobileDevicePreview
                                                              ? "9"
                                                              : "12",
                                                            cols: "12"
                                                          }
                                                        },
                                                        [
                                                          _c("v-textarea", {
                                                            attrs: {
                                                              loading:
                                                                _vm
                                                                  .uploadCreative[
                                                                  i
                                                                ].parsingText ||
                                                                _vm
                                                                  .uploadCreative[
                                                                  i
                                                                ].uploading2,
                                                              outlined: "",
                                                              rows: "12",
                                                              color:
                                                                _vm
                                                                  .$_splitMixin_splitColors[
                                                                  i
                                                                ],
                                                              "data-wsc-autocreate":
                                                                "true",
                                                              "data-wsc-lang":
                                                                "en_US",
                                                              "full-width": !item.textMobileDevicePreview
                                                            },
                                                            on: {
                                                              blur: function(
                                                                $event
                                                              ) {
                                                                _vm.focusedSplit = null
                                                                _vm.setSplitValue(
                                                                  i,
                                                                  "textAccessibility"
                                                                )
                                                              },
                                                              focus: function(
                                                                $event
                                                              ) {
                                                                _vm.focusedSplit = i
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                item.textAccessibility,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  item,
                                                                  "textAccessibility",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "item.textAccessibility"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "success",
                    disabled:
                      _vm.isLoading() ||
                      _vm.editorUnsaved.every(function(x) {
                        return !x
                      }) ||
                      _vm.inCodeView.some(function(x) {
                        return x
                      })
                  },
                  on: {
                    click: function($event) {
                      return _vm.saveAllHtmls()
                    }
                  }
                },
                [
                  _vm._v("\n        Save All Html(s)"),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("\n          mdi-content-save-all\n        ")
                  ])
                ],
                1
              ),
              _vm.inCodeView.some(function(x) {
                return x
              })
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "ml-2",
                                      attrs: {
                                        readonly: "",
                                        dark: "",
                                        color: "grey",
                                        disabled: _vm.isNextDisabled
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v("\n            Next"),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v(
                                        "\n              mdi-menu-right-outline\n            "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4090661689
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Please exit Code View on Split(s) to save Creative(s)"
                        )
                      ])
                    ]
                  )
                : _c(
                    "v-menu",
                    {
                      attrs: {
                        "x-offset": "",
                        origin: "left",
                        transition: "slide-x-transition"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var onMenu = ref.on
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    color: "success",
                                    disabled:
                                      _vm.isLoading() ||
                                      _vm.editorUnsaved.some(function(x) {
                                        return x
                                      }) ||
                                      _vm.isNextDisabled ||
                                      _vm.subjectLineResponse.score === 0
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.validateEmailSetup()
                                    }
                                  }
                                },
                                [
                                  _vm._v("\n            Next"),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v(
                                      "\n              mdi-menu-right-outline\n            "
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.warningMenu,
                        callback: function($$v) {
                          _vm.warningMenu = $$v
                        },
                        expression: "warningMenu"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-text", { staticClass: "pa-5" }, [
                            _c("span", [
                              _vm._v(
                                "Your creative(s) contain(s) warnings. Would you like to continue?"
                              )
                            ])
                          ]),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "tertiary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.expandWarnings()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              No, let me review\n            "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.advanceFromEmailSetup()
                                    }
                                  }
                                },
                                [_vm._v("\n              Yes\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "v-card-subtitle",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.subjectLineResponse.score === 0,
                  expression: "subjectLineResponse.score === 0"
                }
              ],
              staticClass: "text-right",
              staticStyle: { color: "red" }
            },
            [
              _vm._v(
                "\n      Please run Subject Analyzer Tool to move forward\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }