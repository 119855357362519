<template>
  <v-card id="emailSetup">
    <v-form>
      <v-card-text class="pa-2">
        <v-container>
          <v-row>
            <v-col
              v-if="error"
              cols="12"
            >
              <p class="red--text font-weight-bold text-left">
                <v-icon
                  color="red"
                  class="mr-1"
                >
                  mdi-alert-circle-outline
                </v-icon>
                {{ error }}
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              lg="12"
              class="pa-0"
            >
              <v-card>
                <v-card-title class="pl-3">
                  Mailing {{ $_splitMixin_isSplitTest ? 'Test Splits' : $_splitMixin_isTrueSplit ? 'Splits' : '' }}
                  <v-spacer />
                  <v-tooltip
                    v-if="isMMSAdmin"
                    top
                  >
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-menu
                        v-model="adminInfo"
                        right
                        x-offset
                        origin="right"
                        transition="slide-x-transition"
                        :close-on-content-click="false"
                        :close-on-click="false"
                      >
                        <template v-slot:activator="{ on: onMenu }">
                          <v-btn
                            x-small
                            fab
                            color="info"
                            class="mr-2"
                            v-on="{ ...onMenu, ...onTooltip }"
                          >
                            <v-icon>mdi-information</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-text class="px-5 py-2">
                            <LabelValue
                              label="Campaign Id"
                              :value="$store.getters['mailing/getCampaignId']()"
                            />
                            <LabelValue
                              label="Tenant CRM Id"
                              :value="$store.getters['mailing/getMailingTenantCrmId']()"
                            />
                            <LabelValue
                              label="Target Id"
                              :value="$store.getters['mailing/getTargetId']()"
                            />
                            <LabelValue
                              label="Mailing Id"
                              :value="$store.getters['mailing/getMailingItemId']()"
                            />
                            <LabelValue
                              v-for="(split, i) in splitMixin_splits"
                              :key="`admin-split-${i}`"
                              :label="`Split Id ${$_splitMixin_splitLetters[i]}`"
                              :value="split.id"
                            />
                          </v-card-text>
                          <v-divider />
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="tertiary"
                              @click="adminInfo = false"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </template>
                    <span>Admin Details</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="($_splitMixin_isMaxSplits || isLoading() || splitMixin_splits.length == 4) || isClicked"
                        x-small
                        fab
                        color="success"
                        v-on="on"
                        @click="addSplit"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Add Split</span>
                  </v-tooltip>

                  <v-btn
                    v-show="$_splitMixin_isSplit"
                    class="ml-2"
                    icon
                    @click="showSplits = !showSplits"
                  >
                    <v-icon>{{ showSplits ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                  </v-btn>
                </v-card-title>

                <!-- <v-card-subtitle v-if="splitError" class="pt-2 px-3" style="height: 54px">
                    <span v-if="splitError" class="red--text text-h6">
                      <v-icon color="red" size="32" class="mr-1">mdi-alert-circle-outline</v-icon>
                      {{ splitError }}
                    </span>
                  </v-card-subtitle> -->
                <v-card-subtitle
                  v-if="$_splitMixin_isSplit"
                  class="pt-2 px-10 d-flex align-center"
                  style="height: 54px"
                >
                  <span
                    v-for="(split, i) in splitMixin_splits"
                    :key="`splits-${i}`"
                    :style="`width: ${split.distribution}%`"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-progress-linear
                          striped
                          :height="focusedSplit === i ? '30' : '20'"
                          :rounded="focusedSplit === i"
                          value="100"
                          :color="$_splitMixin_splitColors[i]"
                          v-on="on"
                        >
                          <template v-slot="{ value }">
                            <strong>{{ split.distribution }}%</strong>
                          </template>
                        </v-progress-linear>
                      </template>
                      <span>{{ split.subject || `Subject ${$_splitMixin_indexToChar(i)}` }}</span>
                    </v-tooltip>
                  </span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-progress-linear
                        :style="`width: ${$_splitMixin_winningDistribution}%`"
                        :height="focusedSplit === -1 ? '30' : '20'"
                        :rounded="focusedSplit === -1"
                        value="100"
                        :color="$_splitMixin_winningColor"
                        v-on="on"
                      >
                        <template v-slot="{ value }">
                          <strong>{{ $_splitMixin_winningDistribution }}%</strong>
                        </template>
                      </v-progress-linear>
                    </template>
                    <span>{{ winningCriteria && $_splitMixin_winningCriteriaSelect.find(x => x.value === winningCriteria) ? $_splitMixin_winningCriteriaSelect.find(x => x.value === winningCriteria).text : '' }}</span>
                  </v-tooltip>
                </v-card-subtitle>

                <v-card-text
                  v-if="$_splitMixin_isSplit"
                  class="px-10 d-flex align-center"
                >
                  <v-row>
                    <v-col
                      cols="4"
                      sm="3"
                      md="2"
                    >
                      <v-progress-linear
                        height="20"
                        :value="$_splitMixin_winningDistribution"
                        :color="$_splitMixin_winningColor"
                      >
                        <template v-slot="{ value }">
                          <strong>{{ $_splitMixin_winningDistribution }}%</strong>
                        </template>
                      </v-progress-linear>
                    </v-col>
                    <v-col
                      cols="9"
                      lg="3"
                    >
                      <v-select
                        v-model="winningCriteria"
                        :items="$_splitMixin_winningCriteriaSelect"
                        :disabled="!$_splitMixin_isSplitTest"
                        dense
                        :color="$_splitMixin_winningColor"
                        :item-color="$_splitMixin_winningColor"
                        label="Winning Criteria"
                        :error-messages="winningCriteriaErrors"
                        @input="$v.winningCriteria.$touch()"
                        @blur="$v.winningCriteria.$touch(); focusedSplit = null; setMailingValue('winningCriteria')"
                        @focus="focusedSplit = -1"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider v-show="showSplits && $_splitMixin_isSplit" />

                <v-card-text v-show="showSplits">
                  <div
                    v-if="splitMixin_expanded.length > 0 && scrollSplitIndex !== -1"
                    class="fab-container"
                    :style="{ right: (chatDrawer ? 420 : 0) + 'px' }"
                  >
                    <v-speed-dial
                      v-model="splitActionsFab"
                      top
                      right
                      direction="bottom"
                      transition="slide-y-transition"
                    >
                      <template v-slot:activator>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-model="splitActionsFab"
                              :color="$_splitMixin_splitColors[scrollSplitIndex]"
                              large
                              dark
                              fab
                              v-on="on"
                            >
                              <v-icon v-if="splitActionsFab">
                                mdi-close
                              </v-icon>
                              <span
                                v-else
                                class="text-h5"
                              >
                                {{ $_splitMixin_indexToChar(scrollSplitIndex) }}
                              </span>
                            </v-btn>
                          </template>
                          <span>Split {{ $_splitMixin_indexToChar(scrollSplitIndex) }} Actions</span>
                        </v-tooltip>
                      </template>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :disabled="$_splitMixin_isMaxSplits || isLoading() || splitMixin_splits.length == 4 || isClicked"
                            fab
                            small
                            color="info"
                            v-on="on"
                            @click="copySplit()"
                          >
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                        <span>Copy Split</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :disabled="splitMixin_splits.length <= 1 || isLoading()"
                            fab
                            small
                            color="error"
                            v-on="on"
                            @click="removeSplit()"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Remove Split</span>
                      </v-tooltip>
                    </v-speed-dial>
                  </div>
                  <v-expand-transition>
                    <v-expansion-panels
                      v-model="splitMixin_expanded"
                      multiple
                    >
                      <v-expansion-panel
                        v-for="(item, i) in splitMixin_splits"
                        ref="splitPanel"
                        :key="`panel-${i}`"
                      >
                        <v-expansion-panel-header>
                          <v-row>
                            <v-col
                              v-if="$_splitMixin_isSplit"
                              cols="4"
                              sm="3"
                              md="2"
                            >
                              <v-progress-linear
                                striped
                                height="20"
                                :value="item.distribution"
                                :color="$_splitMixin_splitColors[i]"
                              >
                                <template v-slot="{ value }">
                                  <strong>{{ item.distribution }}%</strong>
                                </template>
                              </v-progress-linear>
                            </v-col>
                            <v-col
                              v-if="$_splitMixin_isSplit"
                              cols="8"
                              sm="9"
                              md="10"
                            >
                              <span>Split {{ $_splitMixin_indexToChar(i) }}</span>
                              <v-slider
                                v-show="!splitMixin_expanded.includes(i)"
                                v-model="item.distribution"
                                :style="`width: ${item.distribution + $_splitMixin_winningDistribution}%`"
                                thumb-label="always"
                                :color="$_splitMixin_splitColors[i]"
                                :thumb-color="$_splitMixin_splitColors[i]"
                                :track-color="$_splitMixin_winningColor"
                                min="0"
                                :max="item.distribution + $_splitMixin_winningDistribution"
                                hide-details
                                class="ml-4"
                                @click.stop
                                @end="focusedSplit = null; setSplitValue(i, 'distribution')"
                                @focus="focusedSplit = i"
                              />
                            </v-col>
                          </v-row>
                          <template v-slot:actions>
                            <v-icon
                              :color="$_splitMixin_splitColors[i]"
                            >
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          v-intersect.quiet="{
                            handler: onIntersect,
                            options: {
                              threshold: [0.0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0]
                            }
                          }"
                          :class="$_splitMixin_indexToChar(i)"
                        >
                          <v-row>
                            <v-col
                              v-if="$_splitMixin_isSplit"
                              cols="12"
                              sm="3"
                              md="2"
                            >
                              <v-text-field
                                v-model="item.distribution"
                                :error-messages="distributionErrors(i)"
                                dense
                                :color="$_splitMixin_splitColors[i]"
                                type="number"
                                :label="`${$_splitMixin_indexToChar(i)} %`"
                                append-icon="mdi-percent"
                                hide-details
                                min="0"
                                :max="item.distribution + $_splitMixin_winningDistribution"
                                @blur="focusedSplit = null; setSplitValue(i, 'distribution')"
                                @focus="focusedSplit = i"
                              />
                            </v-col>
                            <v-col
                              v-if="$_splitMixin_isSplit"
                              cols="12"
                              sm="9"
                              md="10"
                            >
                              <v-slider
                                v-model="item.distribution"
                                :style="`width: ${item.distribution + $_splitMixin_winningDistribution}%`"
                                thumb-label="always"
                                :color="$_splitMixin_splitColors[i]"
                                :thumb-color="$_splitMixin_splitColors[i]"
                                :track-color="$_splitMixin_winningColor"
                                min="0"
                                :max="item.distribution + $_splitMixin_winningDistribution"
                                hide-details
                                @end="focusedSplit = null; setSplitValue(i, 'distribution')"
                                @focus="focusedSplit = i"
                              />
                            </v-col>
                            <!-- <v-col
                              cols="12"
                              sm="6"
                              lg="3"
                              class="d-flex align-center
                            >
                              <v-switch
                                v-model="item.clickToViewLink"
                                dense
                                hide-details
                                :color="$_splitMixin_splitColors[i]"
                                label="Show 'Click to view' link?"
                                @change="setSplitValue(i, 'clickToViewLink')"
                              />
                            </v-col> -->
                            <v-col
                              cols="12"
                              sm="6"
                              lg="4"
                            >
                              <v-text-field
                                v-model="item.fromName"
                                :error-messages="fromNameErrors(i)"
                                dense
                                :color="$_splitMixin_splitColors[i]"
                                label="From Name"
                                @input="$v.splitMixin_splits.$each[i].fromName.$touch()"
                                @blur="$v.splitMixin_splits.$each[i].fromName.$touch(); focusedSplit = null; setSplitValue(i, 'fromName')"
                                @focus="focusedSplit = i"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              lg="5"
                            >
                              <v-text-field
                                v-model="item.fromAddress"
                                :error-messages="fromAddressErrors(i)"
                                dense
                                :color="$_splitMixin_splitColors[i]"
                                label="From Address"
                                :suffix="`${isSharedDomain ? '@med.email' : item.replyAddress}`"
                                @input="$v.splitMixin_splits.$each[i].fromAddress.$touch()"
                                @blur="$v.splitMixin_splits.$each[i].fromAddress.$touch(); focusedSplit = null; setSplitValue(i, 'fromAddress')"
                                @focus="focusedSplit = i"
                              >
                                <template
                                  v-if="isSharedDomain"
                                  v-slot:append
                                >
                                  <v-tooltip
                                    top
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        :color="$_splitMixin_splitColors[i]"
                                        v-on="on"
                                      >
                                        mdi-help-circle-outline
                                      </v-icon>
                                    </template>
                                    <span>Broadcast could be sent off shared domains: </span><br>
                                    <span>{{ domains }}</span>
                                  </v-tooltip>
                                </template>
                                <!-- <template v-slot:append-outer>
                                  <span class="mt-1">.com</span>
                                </template> -->
                              </v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <v-textarea
                                v-model="item.subject"
                                :error-messages="subjectErrors(i)"
                                dense
                                :color="$_splitMixin_splitColors[i]"
                                counter="255"
                                :label="`Subject ${$_splitMixin_indexToChar(i)}`"
                                auto-grow
                                no-resize
                                rows="1"
                                data-wsc-autocreate="true"
                                data-wsc-lang="en_US"
                                @keydown.enter.prevent
                                @change="item.subject = removeEndLine(item.subject)"
                                @input="$v.splitMixin_splits.$each[i].subject.$touch(); disableSLA()"
                                @blur="$v.splitMixin_splits.$each[i].subject.$touch(); focusedSplit = null; setSplitValue(i, 'subject')"
                                @focus="focusedSplit = i"
                              >
                                <template v-slot:append-outer>
                                  <v-tooltip
                                    top
                                  >
                                    <template v-slot:activator="{ on: onTooltip }">
                                      <v-btn
                                        class="mb-1"
                                        :disabled="!item.subject"
                                        x-small
                                        fab
                                        :color="$_splitMixin_splitColors[i]"
                                        v-on="onTooltip"
                                        @click="validateSubjectLine(item.subject, item.id, i)"
                                      >
                                        <v-icon>
                                          mdi-lightbulb-on-outline
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Analyze Subject Line</span>
                                  </v-tooltip>
                                </template>
                              </v-textarea>
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <v-card
                                v-show="showSubjectLineData.index === i && isSubjLineClicked"
                                ref="subjLine"
                                class="mr-7"
                                outlined
                              >
                                <v-card-subtitle class="text-h5">
                                  Your Subject Line Score:
                                </v-card-subtitle>
                                <v-card-title class="ml-2">
                                  "{{ subjectLineResponse.subject }}"
                                </v-card-title>
                                <v-card-text>
                                  <v-row
                                    justify="space-between"
                                  >
                                    <v-col
                                      cols="6"
                                      sm="6"
                                      class="text-left d-flex flex-wrap"
                                    >
                                      <v-list-item three-line>
                                        <v-list-item-content>
                                          <div>
                                            <div class="mb-2">
                                              <span class="text-h5 font-weight-bold text-center mb-5 mt-3">Your score is </span>
                                              <span
                                                class="text-h2 font-weight-bold text-center mb-5 mt-3"
                                                :style="`color: ${getScoreColor(subjectLineResponse.score)}`"
                                              >
                                                {{ subjectLineResponse.score >= 50 ? subjectLineResponse.score : 50 }}</span> <span class="text-h5 font-weight-bold text-center mb-5 mt-3"> out of 100</span>
                                            </div>
                                            <v-progress-linear
                                              v-model="subjectLineResponse.score"
                                              :color="getScoreColor(subjectLineResponse.score)"
                                              height="13"
                                              value="20"
                                              valuemax="100"
                                              striped
                                            />
                                            <v-list-item-subtitle class="mt-3">
                                              {{ subjectLineResponse.message }}
                                            </v-list-item-subtitle>
                                          </div>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-col>
                                    <v-col
                                      cols="6"
                                      sm="6"
                                    >
                                      <v-divider />
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item v-show="riskWordList.length > 0">
                                            <v-card-subtitle class="text-h6 pb-1">
                                              The following word(s): <span class="font-weight-bold font-italic">{{ riskWordList.toString().replace(/\[|\]/g, "").replace(/,/g, ', ') }}</span> are impacting your final score.
                                            </v-card-subtitle>
                                          </v-list-item>
                                          <v-list-item v-show="pairWordList.length > 0">
                                            <v-card-subtitle class="text-h6 pb-1">
                                              The following phrases: <span class="font-weight-bold font-italic">{{ pairWordList.toString().replace(/\[|\]/g, "").replace(/,/g, ', ') }}</span> are impacting your final score.
                                            </v-card-subtitle>
                                          </v-list-item>
                                          <v-list-item
                                            v-for="(message, j) in summaryMessage"
                                            :key="j"
                                            style="justify-content: left"
                                          >
                                            <v-card-subtitle
                                              class="text-h6 pb-1"
                                            >
                                              {{ message }}
                                            </v-card-subtitle>
                                          </v-list-item>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-card-actions>
                                  <v-btn
                                    class="ml-5 mb-2"
                                    color="primary"
                                    href="https://insp01.blob.core.windows.net/public/noww_docs/Subject_Line_Best_Practices.pdf"
                                    target="_blank"
                                  >
                                    Explore Best Practices
                                    <v-icon
                                      class="pl-3"
                                      size="18"
                                    >
                                      mdi-open-in-new
                                    </v-icon>
                                  </v-btn>
                                  <v-btn
                                    class="ml-5 mb-2"
                                    color="tertiary"
                                    @click="closeSubjLineDialog"
                                  >
                                    close
                                  </v-btn>
                                  <v-spacer />
                                  <v-card-subtitle
                                    v-show="subjectLineResponse.score <= 50"
                                    class="mt-1"
                                    style="color: red"
                                  >
                                    *Contact your CSM for authorization if you would like to proceed with this subject line.
                                  </v-card-subtitle>
                                </v-card-actions>
                                <v-fade-transition>
                                  <v-overlay
                                    v-if="isSlaDisabled"
                                    absolute
                                    opacity=".70"
                                    color="#FFFFFF"
                                  >
                                    <v-btn
                                      color="primary"
                                      @click="validateSubjectLine(item.subject, item.id, i)"
                                    >
                                      Re-run analysis
                                    </v-btn>
                                  </v-overlay>
                                </v-fade-transition>
                              </v-card>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                            >
                              <v-textarea
                                v-model="item.previewText"
                                :error-messages="previewTextErrors(i)"
                                dense
                                :color="$_splitMixin_splitColors[i]"
                                counter="140"
                                label="Preview Text"
                                auto-grow
                                clearable
                                no-resize
                                rows="1"
                                data-wsc-autocreate="true"
                                data-wsc-lang="en_US"
                                @keydown.enter.prevent
                                @change="item.previewText = removeEndLine(item.previewText)"
                                @input="$v.splitMixin_splits.$each[i].previewText.$touch()"
                                @blur="$v.splitMixin_splits.$each[i].previewText.$touch(); focusedSplit = null; setSplitValue(i, 'previewText')"
                                @focus="focusedSplit = i"
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              class="d-flex justify-center"
                            >
                              <v-dialog
                                v-model="item.clChooseDialog"
                                persistent
                                scrollable
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    color="success"
                                    :disabled="isLoading()"
                                    v-on="on"
                                  >
                                    <v-icon left>
                                      mdi-cloud-download
                                    </v-icon>
                                    Choose Creative from Content
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-card-title
                                    class="text-h6 grey lighten-2"
                                    primary-title
                                  >
                                    Content Selection
                                  </v-card-title>

                                  <v-card-text
                                    class="black--text pt-5"
                                  >
                                    <div class="font-weight-bold red--text">
                                      By selecting a new creative, you will lose any content currently in your editor and text version of creative.
                                    </div>
                                    <v-tabs
                                      v-model="clTab"
                                      centered
                                      fixed-tabs
                                    >
                                      <v-tab>
                                        Content Library
                                      </v-tab>
                                      <v-tab>
                                        Template Library
                                      </v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="clTab">
                                      <v-tab-item>
                                        <ContentLibraryMain
                                          ref="clChooseContent"
                                          :root="clHtmlRoot"
                                          no-rename-folder
                                          no-delete-folder
                                          no-create-folder
                                          no-download-folder
                                          no-upload-files
                                          no-new-creative
                                          no-delete-checked
                                          no-download-checked
                                          no-details-selected
                                          :checkable="false"
                                          @selected="clHtmlSelected"
                                          @unselected="clHtmlUnselected()"
                                        />
                                      </v-tab-item>
                                      <v-tab-item>
                                        <TemplateLibrary
                                          ref="templateLibrary"
                                          @change="templateChange"
                                        />
                                      </v-tab-item>
                                    </v-tabs-items>
                                  </v-card-text>

                                  <v-divider />

                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                      color="tertiary"
                                      :disabled="item.clHtmlCopying"
                                      @click="closeCLChooseDialog(item)"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      v-if="clTab === 0"
                                      :disabled="!clHtmlSelectedItem"
                                      :loading="item.clHtmlCopying"
                                      color="success"
                                      @click="copyCLHtmlToStage(item, i)"
                                    >
                                      Select
                                    </v-btn>
                                    <v-btn
                                      v-else-if="clTab === 1"
                                      :loading="item.clHtmlCopying"
                                      color="success"
                                      :disabled="clSelectedTemplate === null"
                                      @click="copyCLTemplateToStage(item, i)"
                                    >
                                      Select
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-divider
                                class="mx-4 d-none d-sm-flex"
                                vertical
                              />
                              <v-menu
                                v-model="uploadCreative[i].menu"
                                right
                                x-offset
                                origin="right"
                                transition="slide-x-transition"
                                :close-on-content-click="false"
                                :close-on-click="false"
                              >
                                <template v-slot:activator="{ on: onMenu }">
                                  <v-btn
                                    color="success"
                                    :disabled="isLoading()"
                                    v-on="{ ...onMenu }"
                                  >
                                    <v-icon left>
                                      mdi-text-box-plus
                                    </v-icon>
                                    Upload New
                                  </v-btn>
                                </template>
                                <v-card :width="menuWidth">
                                  <v-card-text class="px-5 py-2">
                                    <v-file-input
                                      v-model="uploadCreative[i].file"
                                      :rules="uploadCreativeRules"
                                      :accept="acceptedFileExtensions"
                                      :show-size="1000"
                                      :error-messages="uploadCreative[i].errors"
                                      counter
                                      color="secondary"
                                      label="Upload File"
                                      @change="uploadCreative[i].errors = []"
                                      @click:clear="uploadCreative[i].file = null"
                                    >
                                      <template v-slot:selection="{ text }">
                                        <v-chip
                                          color="secondary"
                                          dark
                                          label
                                          small
                                        >
                                          {{ text }}
                                        </v-chip>
                                      </template>
                                    </v-file-input>
                                    <div class="font-italic pl-7 inline-block">
                                      Valid file extensions: {{ acceptedFileExtensions }}
                                    </div>
                                    <div class="font-weight-bold pl-7 red--text inline-block">
                                      By uploading a new creative, you will lose any content currently in your editor and text version of creative.
                                    </div>
                                  </v-card-text>
                                  <v-divider />
                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                      color="tertiary"
                                      :disabled="uploadCreative[i].uploading"
                                      @click="closeUploadCreative(item, i)"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="success"
                                      :loading="uploadCreative[i].uploading || isLoading()"
                                      @click="uploadCreativeSave(item, i)"
                                    >
                                      Upload
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-menu>
                              <v-divider
                                class="mx-4 d-none d-sm-flex"
                                vertical
                              />
                              <v-tooltip
                                top
                              >
                                <template v-slot:activator="{ on: onTooltip }">
                                  <v-btn
                                    icon
                                    color="success"
                                    class="mx-1"
                                    v-on="onTooltip"
                                    @click="downloadSplit(item.id)"
                                  >
                                    <v-icon>mdi-file-download</v-icon>
                                  </v-btn>
                                </template>
                                <span>Download Creative</span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on: onTooltip }">
                                  <v-btn
                                    :disabled="isLoading() || !editorUnsaved[i] || inCodeView[i]"
                                    icon
                                    color="success"
                                    class="mx-1"
                                    v-on="{ ...onTooltip }"
                                    @click="saveHtml(i)"
                                  >
                                    <v-icon>mdi-content-save</v-icon>
                                  </v-btn>
                                </template>
                                <span>Save Html</span>
                              </v-tooltip>
                              <v-dialog
                                v-model="optOutPreviewDialog"
                              >
                                <template v-slot:activator="{ on: onDialog }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on: onTooltip }">
                                      <v-btn
                                        :disabled="isLoading()"
                                        icon
                                        color="info"
                                        class="mx-1"
                                        v-on="{ ...onTooltip, ...onDialog }"
                                        @click="openOptOutPreviewDialog()"
                                      >
                                        <v-icon>mdi-text-box</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Preview Opt Out</span>
                                  </v-tooltip>
                                </template>
                                <v-card>
                                  <v-card-title
                                    class="text-h6 grey lighten-2"
                                    primary-title
                                  >
                                    Opt Out Preview
                                  </v-card-title>

                                  <v-card-text
                                    class="black--text pt-5"
                                  >
                                    <div>
                                      <OptOutPreview
                                        :opt-out-id="optOutId"
                                        :edit-mode="optOutEditable"
                                        :footer-margin-bottom="54"
                                      />
                                    </div>
                                  </v-card-text>

                                  <v-divider />

                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                      v-if="!optOutEditable"
                                      color="tertiary"
                                      @click="closeOptOutPreviewDialog()"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      color="success"
                                      @click="closeOptOutPreviewDialog()"
                                    >
                                      Save
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-tooltip top>
                                <template v-slot:activator="{ on: onTooltip }">
                                  <v-btn
                                    icon
                                    :color="item.devicePreview ? 'secondary' : 'info'"
                                    class="mx-1"
                                    v-on="{ ...onTooltip }"
                                    @click="toggleEditorMenu(item, 'devicePreview', !item.devicePreview)"
                                  >
                                    <v-icon>mdi-{{ item.devicePreview ? 'close' : 'tablet-cellphone' }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.devicePreview ? 'Close ' : '' }}Device Preview</span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on: onTooltip }">
                                  <v-btn
                                    icon
                                    :color="item.dragAndDrop ? 'secondary' : 'info'"
                                    class="mx-1"
                                    v-on="{ ...onTooltip }"
                                    @click="toggleEditorMenu(item, 'dragAndDrop', !item.dragAndDrop)"
                                  >
                                    <v-icon>mdi-{{ item.dragAndDrop ? 'close' : 'gesture-swipe' }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.dragAndDrop ? 'Close ' : '' }}Drag & Drop Menu</span>
                              </v-tooltip>
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                              :lg="(item.devicePreview && (!item.deviceWidth || item.deviceWidth < 600)) || item.dragAndDrop ? '8' : '12'"
                            >
                              <p
                                v-for="(htmlError, index) in item.htmlErrors"
                                :key="`htmlErrors-${index}`"
                                class="red--text font-weight-bold text-left"
                              >
                                <v-icon
                                  color="error"
                                  class="mr-1"
                                >
                                  mdi-alert-circle-outline
                                </v-icon>
                                {{ htmlError }}
                              </p>
                              <p
                                v-for="(htmlWarning, index) in item.htmlWarnings"
                                :key="`htmlWarnings-${index}`"
                                class="amber--text font-weight-bold text-left"
                              >
                                <v-icon
                                  color="amber"
                                  class="mr-1"
                                >
                                  mdi-alert-outline
                                </v-icon>
                                {{ htmlWarning }}
                              </p>
                              <div
                                v-if="isInit"
                              >
                                <froala
                                  ref="froalaEditor"
                                  v-model="item.html"
                                  :config="froalaConfig(i)"
                                />
                                <ImageManager
                                  :source="imageRoot"
                                  :destination="item.azImageFolder"
                                />
                              </div>
                              <v-skeleton-loader
                                v-else
                                type="article@3"
                              />
                            </v-col>
                            <v-col
                              v-show="item.devicePreview"
                              cols="12"
                              :lg="!item.deviceWidth || item.deviceWidth < 600 ? '4' : '12'"
                            >
                              <SplitDevicePreview
                                ref="splitDevicePreview"
                                :html="item.html"
                                :text-accessibility="item.textAccessibility"
                                no-desktop
                                @change="value => changedDevice(value, item, i)"
                              />
                            </v-col>
                            <v-col
                              v-show="item.dragAndDrop"
                              cols="12"
                              lg="4"
                            >
                              <DragAndDrop v-model="item.dragAndDropExpanded" />
                            </v-col>
                            <v-col
                              cols="12"
                              class="d-flex justify-center"
                            >
                              <v-btn
                                v-if="!item.textAccessibility"
                                color="success"
                                :disabled="isLoading()"
                                :loading="uploadCreative[i].parsingText"
                                @click="parseCreativeTextSave(item, i)"
                              >
                                <v-icon left>
                                  mdi-book-open-variant
                                </v-icon>
                                Parse Creative Text
                              </v-btn>
                              <v-menu
                                v-else
                                v-model="uploadCreative[i].textMenu"
                                right
                                x-offset
                                origin="right"
                                transition="slide-x-transition"
                                :close-on-content-click="false"
                                :close-on-click="false"
                              >
                                <template v-slot:activator="{ on: onMenu }">
                                  <v-btn
                                    color="success"
                                    :disabled="isLoading()"
                                    v-on="{ ...onMenu }"
                                  >
                                    <v-icon left>
                                      mdi-book-open-variant
                                    </v-icon>
                                    Parse Creative Text
                                  </v-btn>
                                </template>
                                <v-card :width="menuWidth">
                                  <v-card-text class="px-5 py-4">
                                    <div class="font-weight-bold pl-7 red--text inline-block">
                                      By parsing the text out of your creative, you will lose any content currently in the text version of your creative.
                                    </div>
                                  </v-card-text>
                                  <v-divider />
                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                      color="tertiary"
                                      :disabled="uploadCreative[i].parsingText"
                                      @click="closeParseCreativeText(item, i)"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="success"
                                      :loading="uploadCreative[i].parsingText || isLoading()"
                                      @click="parseCreativeTextSave(item, i)"
                                    >
                                      Ok
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-menu>
                              <v-divider
                                class="mx-4 d-none d-sm-flex"
                                vertical
                              />
                              <v-menu
                                v-model="uploadCreative[i].menu2"
                                right
                                x-offset
                                origin="right"
                                transition="slide-x-transition"
                                :close-on-content-click="false"
                                :close-on-click="false"
                              >
                                <template v-slot:activator="{ on: onMenu }">
                                  <v-btn
                                    color="success"
                                    :disabled="isLoading()"
                                    v-on="{ ...onMenu }"
                                  >
                                    <v-icon left>
                                      mdi-text-box-plus
                                    </v-icon>
                                    Upload Text
                                  </v-btn>
                                </template>
                                <v-card :width="menuWidth">
                                  <v-card-text class="px-5 py-2">
                                    <v-file-input
                                      v-model="uploadCreative[i].file2"
                                      :rules="uploadCreativeRules"
                                      :accept="acceptedTextFileExtensions"
                                      :show-size="1000"
                                      :error-messages="uploadCreative[i].errors2"
                                      counter
                                      color="secondary"
                                      label="Upload File"
                                      @change="uploadCreative[i].errors2 = []"
                                      @click:clear="uploadCreative[i].file2 = null"
                                    >
                                      <template v-slot:selection="{ text }">
                                        <v-chip
                                          color="secondary"
                                          dark
                                          label
                                          small
                                        >
                                          {{ text }}
                                        </v-chip>
                                      </template>
                                    </v-file-input>
                                    <div class="font-italic pl-7 inline-block">
                                      Valid file extensions: {{ acceptedTextFileExtensions }}
                                    </div>
                                    <div class="font-weight-bold pl-7 red--text inline-block">
                                      By uploading a new text version, you will lose any content currently in your text version of creative.
                                    </div>
                                  </v-card-text>
                                  <v-divider />
                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                      color="tertiary"
                                      :disabled="uploadCreative[i].uploading2"
                                      @click="closeUploadText(item, i)"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="success"
                                      :loading="uploadCreative[i].uploading2 || isLoading()"
                                      @click="uploadTextSave(item, i)"
                                    >
                                      Upload
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-menu>
                              <v-divider
                                class="mx-4 d-none d-sm-flex"
                                vertical
                              />
                              <v-menu
                                right
                                x-offset
                                origin="right"
                                transition="slide-x-transition"
                              >
                                <template v-slot:activator="{ on: onMenu }">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on: onTooltip }">
                                      <v-btn
                                        color="error"
                                        icon
                                        class="mx-1 d-none d-sm-flex"
                                        :disabled="isLoading() || !item.textAccessibility"
                                        v-on="{ ...onMenu, ...onTooltip }"
                                      >
                                        <v-icon>
                                          mdi-text-box-minus
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Clear Text Version</span>
                                  </v-tooltip>
                                </template>
                                <v-card :width="menuWidth">
                                  <v-card-text class="pa-5">
                                    <span>Are you sure you want to clear the text version?</span>
                                  </v-card-text>
                                  <v-divider />
                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                      color="tertiary"
                                      :disabled="isLoading()"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      color="error"
                                      :loading="isLoading()"
                                      @click="clearTextAccessibility(i)"
                                    >
                                      Yes
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-menu>
                              <v-tooltip top>
                                <template v-slot:activator="{ on: onTooltip }">
                                  <v-btn
                                    icon
                                    color="info"
                                    class="mx-1 d-none d-sm-flex"
                                    v-on="{ ...onTooltip }"
                                    @click="toggleTextMenu(item, 'textMobileDevicePreview', !item.textMobileDevicePreview)"
                                  >
                                    <v-icon>mdi-{{ item.textMobileDevicePreview ? 'monitor' : 'cellphone' }}</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ item.textMobileDevicePreview ? 'Desktop' : 'Phone' }} Device Preview</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col
                              :xl="item.textMobileDevicePreview ? '4' : '12'"
                              :lg="item.textMobileDevicePreview ? '5' : '12'"
                              :md="item.textMobileDevicePreview ? '7' : '12'"
                              :sm="item.textMobileDevicePreview ? '9' : '12'"
                              cols="12"
                              class="d-flex justify-center"
                            >
                              <v-textarea
                                v-model="item.textAccessibility"
                                :loading="uploadCreative[i].parsingText || uploadCreative[i].uploading2"
                                outlined
                                rows="12"
                                :color="$_splitMixin_splitColors[i]"
                                data-wsc-autocreate="true"
                                data-wsc-lang="en_US"
                                :full-width="!item.textMobileDevicePreview"
                                @blur="focusedSplit = null; setSplitValue(i, 'textAccessibility')"
                                @focus="focusedSplit = i"
                              />
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expand-transition>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          :disabled="isLoading() || editorUnsaved.every(x => !x) || inCodeView.some(x => x)"
          @click="saveAllHtmls()"
        >
          Save All Html(s)<v-icon right>
            mdi-content-save-all
          </v-icon>
        </v-btn>
        <v-tooltip
          v-if="inCodeView.some(x => x)"
          top
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-2"
              readonly
              dark
              color="grey"
              :disabled="isNextDisabled"
              v-on="on"
            >
              Next<v-icon right>
                mdi-menu-right-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Please exit Code View on Split(s) to save Creative(s)</span>
        </v-tooltip>
        <v-menu
          v-else
          v-model="warningMenu"
          x-offset
          origin="left"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on: onMenu }">
            <v-btn
              color="success"
              class="ml-2"
              :disabled="isLoading() || editorUnsaved.some(x => x) || isNextDisabled || subjectLineResponse.score === 0"
              @click="validateEmailSetup()"
            >
              Next<v-icon right>
                mdi-menu-right-outline
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text class="pa-5">
              <span>Your creative(s) contain(s) warnings. Would you like to continue?</span>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="tertiary"
                @click="expandWarnings()"
              >
                No, let me review
              </v-btn>
              <v-btn
                color="success"
                @click="advanceFromEmailSetup()"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-card-actions>
      <v-card-subtitle
        v-show="subjectLineResponse.score === 0"
        class="text-right"
        style="color: red"
      >
        Please run Subject Analyzer Tool to move forward
      </v-card-subtitle>
    </v-form>
  </v-card>
</template>

<script>
import '@/plugins/vue-froala-wysiwyg'
import FroalaEditor from 'froala-editor/js/froala_editor.min'
import { splitMixin, splitMixinMailing, dragAndDropMixin } from '@/shared/mixins/mailing'
import { wscMixin, windowMixin, downloadFileMixin } from '@/shared/mixins/general'
import { validationMixin } from 'vuelidate'
import claims from '@/shared/models/auth/claims'
import {
  required,
  minLength,
  maxLength,
  minValue,
  email,
  requiredIf,
  requiredUnless
} from 'vuelidate/lib/validators'
import { atLeastOneArrayElement, fromAddress } from '@/shared/validators'
import { mapGetters, mapActions, mapState } from 'vuex'
import { fileExtensions } from '@/shared/models/content-library'
import { mailingService, azBlobService, tenantService } from '@/shared/services'
import Vue from 'vue'

import 'codemirror/lib/codemirror.css'
import CodeMirror from 'codemirror/lib/codemirror.js'
import 'codemirror/mode/xml/xml'
import ImageManager from '@/views/pages/components/content-library/ImageManager'
import store from '@/store'

export default {
  name: 'EmailSetup',

  components: {
    ContentLibraryMain: () => import('@/views/pages/components/content-library/ContentLibraryMain'),
    TemplateLibrary: () => import('@/views/pages/components/content-library/TemplateLibrary'),
    OptOutPreview: () => import('@/views/pages/components/mailing/OptOutPreview'),
    SplitDevicePreview: () => import('@/views/pages/components/mailing/SplitDevicePreview'),
    DragAndDrop: () => import('@/views/pages/components/mailing/DragAndDrop'),
    LabelValue: () => import('@/components/custom/LabelValue'),
    ImageManager
  },

  mixins: [validationMixin, splitMixin, splitMixinMailing, wscMixin, windowMixin, dragAndDropMixin, downloadFileMixin],

  validations: {
    createOptOut: {
      requiredIf: requiredIf(function () {
        return this.createOptOutMenu
      })
    },
    winningCriteria: {
      requiredIf: requiredIf(function () {
        return this.$_splitMixin_isSplitTest
      })
    },
    splitMixin_splits: {
      minLength: minLength(1),
      $each: {
        subject: {
          required,
          maxLength: maxLength(255)
        },
        distribution: {
          minValue: minValue(1)
        },
        previewText: {
          maxLength: maxLength(140)
        },
        fromName: {
          required,
          maxLength: maxLength(255)
        },
        fromAddress: {
          required,
          fromAddress,
          maxLength: maxLength(255)
        }
      }
    }
  },

  props: {
    stepVal: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      domains: [],
      domainsInfo: [],
      isSharedDomain: false,
      focusedSplit: null,
      showSplits: true,
      showSubjectLineData: {
        value: false,
        index: 0
      },
      subjectLineResponse: {
        message: '',
        score: 0,
        subject: ''
      },
      isNextDisabled: false,
      aiOpenResult: 0.00,
      aiClickResult: 0.00,
      aiLoading: false,
      summaryMessage: [],
      riskWordList: [],
      pairWordList: [],
      isClicked: false,
      isSubjLineClicked: false,
      isSlaDisabled: false,
      scoreMessage: [],
      splitActionsFab: false,
      error: null,
      scrollSplitIndex: -1,
      scrollMaxThreshold: 0,
      clHtmlRoot: process.env.VUE_APP_CL_HTML,
      clHtmlSelectedItem: null,
      clSelectedTemplate: null,
      clTab: null,
      optOutSelect: [],
      optOutLoading: false,
      createOptOutMenu: false,
      createOptOut: '',
      optOutPreviewDialog: false,
      optOutEditable: false,
      editorUnsaved: [false, false, false, false],
      inCodeView: [false, false, false, false],
      warningMenu: false,
      // sentimentAnalysisResults: {},
      uploadCreativeRules: [
        value => !!value || 'Upload File is required'
      ],
      uploadCreative: [
        {
          menu: false,
          file: null,
          errors: null,
          uploading: false,
          textMenu: false,
          parsingText: false,
          menu2: false,
          file2: null,
          errors2: null,
          uploading2: false
        },
        {
          menu: false,
          file: null,
          errors: null,
          uploading: false,
          textMenu: false,
          parsingText: false,
          menu2: false,
          file2: null,
          errors2: null,
          uploading2: false
        },
        {
          menu: false,
          file: null,
          errors: null,
          uploading: false,
          textMenu: false,
          parsingText: false,
          menu2: false,
          file2: null,
          errors2: null,
          uploading2: false
        },
        {
          menu: false,
          file: null,
          errors: null,
          uploading: false,
          textMenu: false,
          parsingText: false,
          menu2: false,
          file2: null,
          errors2: null,
          uploading2: false
        }
      ],
      menuWidth: 500,
      isInit: false,
      imageRoot: process.env.VUE_APP_CL_IMAGES,
      adminInfo: false
    }
  },

  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('mailing', ['getSplits', 'getMailingTenantCrmId', 'getMailingItemId']),
    ...mapGetters('auth', ['hasClaimKV', 'getRole']),
    ...mapState(['chatDrawer']),
    optOutId: {
      get () {
        return this.$store.getters['mailing/getOptOutId']()
      },
      set (value) {
        // this.$store.dispatch('mailing/setMailingValue', { prop: 'optOutId', value })
        this.$store.commit('mailing/setMailingValue', { prop: 'optOutId', value })
        this.setMailingValue('optOutId')
      }
    },
    winningCriteria: {
      get () {
        return this.$store.getters['mailing/getWinningCriteria']()
      },
      set (value) {
        // this.$store.dispatch('mailing/setMailingValue', { prop: 'winningCriteria', value })
        this.$store.commit('mailing/setMailingValue', { prop: 'winningCriteria', value })
      }
    },
    winningCriteriaErrors () {
      const errors = []
      if (!this.$v.winningCriteria.$dirty) return errors
      !this.$v.winningCriteria.requiredIf &&
        errors.push('Winning Criteria is required')
      return errors
    },
    acceptedFileExtensions () {
      return fileExtensions.getExtensionsString(fileExtensions.htmlExtensionsList)
    },
    acceptedTextFileExtensions () {
      return fileExtensions.getExtensionsString(fileExtensions.textExtensionsList)
    },
    createOptOutErrors () {
      const errors = []
      if (!this.$v.createOptOut.$dirty) return errors
      !this.$v.createOptOut.requiredIf && errors.push('Opt Out Name is required')
      return errors
    },
    isMMSAdmin () {
      return this.hasClaimKV(claims.MMS_TM)
    },
    isMMSOwner () {
      return this.getRole() === 'MMS-Owner'
    }
  },

  created () {
    this.init()
    this.getIsSendingDomain(this.splitMixin_splits[0].replyAddress)
    this.getAcousticLoginsList()
    this.getSharedLoginsList()
  },

  methods: {
    ...mapActions('mailing', ['copyCLHtmlToSplit', 'copyTemplatesHTMLToSplit', 'saveSplitHtml', 'getSplitHtml', 'validateSplit']),
    async init () {
      this.isInit = true
      if (!this.$_splitMixin_isSplit) {
        this.splitMixin_expanded = [0]
      }
      // await this.loadOptOuts()
    },
    destroy () {
      this.isInit = false
    },
    getScoreColor (score) {
      if (score === 100 && score >= 97) return '#43A047'
      if (score <= 96 && score >= 91) return '#81C784'
      if (score <= 90 && score >= 71) return '#FFA726'
      if (score <= 70) return '#EF5350'
    },
    showSubjLineDialog (index) {
      this.showSubjectLineData.value = true
      this.showSubjectLineData.index = index
    },
    closeSubjLineDialog () {
      this.showSubjectLineData.value = false
      this.subjectLineResponse = {}
      this.isSubjLineClicked = false
      this.aiOpenResult = 0
      this.aiClickResult = 0
    },
    disableSLA () {
      this.isSlaDisabled = true
      this.aiOpenResult = 0
      this.aiClickResult = 0
    },
    async validateSubjectLine (subjectLine, splitId, index) {
      this.subjLineLoading = true
      this.aiOpenResult = 0
      this.aiClickResult = 0
      var mailingId = this.$store.getters['mailing/getMailingItemId']()
      var subjectWordCount = subjectLine.split(' ').length
      await mailingService.checkSubjectLine({
        subject: subjectLine,
        splitId: splitId,
        mailingId: mailingId,
        wordCount: subjectWordCount
      })
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.subjectLineResponse = resp
            this.subjLineLoading = false
            this.isSubjLineClicked = true
            this.summaryMessage = this.subjectLineResponse.summaryMessage.split(';')
            this.riskWordList = [...new Set(this.subjectLineResponse.riskWordList)]
            this.pairWordList = this.subjectLineResponse.pairWordList
            this.isSlaDisabled = false
            this.showSubjLineDialog(index)
            if (resp.score <= 50 && !this.isMMSOwner) {
              this.isNextDisabled = true
            }
            if (resp.score > 50) {
              this.isNextDisabled = false
            }
          }
        })
    },

    froalaConfig (index) {
      const context = this
      return {
        toolbarButtons: {
          moreText: {
            'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
          },
          moreParagraph: {
            'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
          },
          moreRich: {
            'buttons': ['insertLink', 'insertImage', /* 'insertVideo', */ 'insertTable', /* 'embedly', */ 'emoticons', 'specialCharacters', 'insertHR']
            // 'buttonsVisible': 5
          },
          moreCustom: {
            'buttons': ['personalizationDialog', 'optOutDialog', 'clickToViewDialog', 'socialDialog', 'forwardToAFriendDialog'],
            'buttonsVisible': 5
          },
          moreMisc: {
            'buttons': ['undo', 'redo', 'html', 'print', 'spellChecker', 'selectAll', 'help', 'fullscreen'],
            'align': 'right'
          }
        },
        pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable',
          'fontFamily', 'fontSize', 'fullscreen', 'image', 'lineBreaker', 'embedly',
          'emoticons', 'entities', 'inlineStyle', 'inlineClass', 'lineHeight', 'lists', 'link', 'paragraphFormat',
          'paragraphStyle', 'quickInsert', 'specialCharacters', 'quote', 'table', 'video', 'wordPaste', 'print',
          'optOut', 'clickToView', 'personalization', 'forwardToAFriend'],
        htmlAllowedTags: ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote',
          'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog',
          'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'font', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
          'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main',
          'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param',
          'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source', 'span',
          'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track',
          'u', 'ul', 'var', 'video', 'wbr'],
        fontSize: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '18', '20', '22', '24', '26', '28', '30', '36', '48', '60', '72'],
        // tableEditButtons: [['tableHeader', 'tableRemove'], ['tableRows', 'tableColumns', 'tableStyle', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign', 'tableCellStyle']],
        imageEditButtons: ['imageAlign', 'imageCaption', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
        imageUploadURL: `${process.env.VUE_APP_INSCRIPTIO_API}${process.env.VUE_APP_API}/azureblob/split/upload/image`,
        imageUploadMethod: process.env.VUE_APP_FROALA_IMAGE_METHOD,
        imageMaxSize: process.env.VUE_APP_FROALA_IMAGE_MAX_SIZE,
        imageAllowedTypes: process.env.VUE_APP_FROALA_IMAGE_TYPES.split(','),
        imageUploadParams: {
          tenantCrmId: store.getters['mailing/getMailingTenantCrmId'](),
          root: store.getters['mailing/getSplit'](index).azImageFolder
        },
        imageDefaultMargin: 0, // by default it was 5px
        requestHeaders: {
          authorization: store.getters['auth/getBearerToken']()
        },
        personalizationItems: store.getters['mailing/getPersonalizations'](),
        linkList: [
          {
            text: 'MMS',
            href: 'https://www.mmslists.com',
            target: '_blank'
          },
          {
            text: 'Med-Pub',
            href: 'https://www.med-pub.com',
            target: '_blank'
          },
          {
            text: 'Facebook',
            href: 'https://facebook.com/mmsemail',
            target: '_blank'
          }
        ],
        events: {
          'click': function () {
            try {
              WEBSPELLCHECKER.init({
                container: this.el
              })
            } catch (exception) {
              // console.log(exception)
            }
          },
          'focus': function (e) {
            context.$set(context.editorUnsaved, index, true)
          },
          'image.beforeUpload': function (images) {
            context.$store.dispatch('auth/refreshTokens')
          },
          'commands.after': function (cmd, param1, param2) {
            if (cmd === 'html') {
              context.$set(context.inCodeView, index, !context.inCodeView[index])
              // if (!context.inCodeView[index]) {
              //   context.$set(context.editorUnsaved, index, true)
              // }
            }
          },
          'drop': function (event) {
            // since refs can be created in different order, need to compare against class list
            let editor = context.$refs.froalaEditor.find(x => x.$el.classList.contains(context.$_splitMixin_indexToChar(index))).getEditor()

            // Focus at the current posisiton.
            editor.markers.insertAtPoint(event.originalEvent)
            let $marker = editor.$el.find('.fr-marker')
            $marker.replaceWith(FroalaEditor.MARKERS)
            editor.selection.restore()

            // Save into undo stack the current position.
            if (!editor.undo.canDo()) editor.undo.saveStep()

            // Insert HTML.
            let dragItem = context.dragAndDropMixin_items.find(x => x.id === localStorage.getItem('drag'))
            editor.html.insert(dragItem.content)
            editor.events.trigger('focus')
            editor.events.trigger('blur')

            // insert style
            editor.html.insert(context.dragAndDropMixin_style.style)

            // Save into undo stack the changes.
            editor.undo.saveStep()

            // Stop event propagation.
            event.preventDefault()
            event.stopPropagation()

            // Firefox show cursor.
            if (editor.core.hasFocus() && editor.browser.mozilla) {
              editor.events.disableBlur()
              setTimeout(function () {
                editor.$el.blur().focus()
                editor.events.enableBlur()
              }, 0)
            }
          }
        },
        htmlRemoveTags: ['script'],
        htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'i'],
        htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async',
          'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite',
          'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer',
          'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden',
          'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max',
          'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder',
          'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped',
          'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style',
          'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap', 'xt', 'xtsn', 'qstparam', 'description'],
        htmlUntouched: true,
        fullPage: true,
        spellcheck: true,
        pastePlain: true,
        heightMax: 800,
        attribution: false,
        editorClass: this.$_splitMixin_indexToChar(index),
        // zIndex: 9990,
        scrollableContainer: '#emailSetup',
        videoResponsive: true,
        codeMirror: CodeMirror,
        codeMirrorOptions: {
          indentWithTabs: true,
          lineNumbers: true,
          lineWrapping: true,
          mode: 'text/html',
          tabMode: 'indent',
          tabSize: 2
        },
        placeholderText: 'Create your content here!',
        key: process.env.VUE_APP_FROALA_LICENSE_KEY
      }
    },
    distributionErrors (i) {
      const errors = []
      if (!this.$v.splitMixin_splits.$each[i].distribution.$dirty) return errors
      !this.$v.splitMixin_splits.$each[i].distribution.minValue &&
        errors.push('Distribution is required')
      return errors
    },
    fromNameErrors (i) {
      const errors = []
      if (!this.$v.splitMixin_splits.$each[i].fromName.$dirty) return errors
      !this.$v.splitMixin_splits.$each[i].fromName.required &&
        errors.push('From Name is required')
      !this.$v.splitMixin_splits.$each[i].fromName.maxLength &&
        errors.push('Cannot be more than 255 characters')
      return errors
    },
    fromAddressErrors (i) {
      const errors = []
      if (!this.$v.splitMixin_splits.$each[i].fromAddress.$dirty) return errors
      !this.$v.splitMixin_splits.$each[i].fromAddress.required &&
        errors.push('From Address is required')
      !this.$v.splitMixin_splits.$each[i].fromAddress.fromAddress &&
        errors.push(`Must be a valid email before "@" (special characters cause deliverability issues)`) // ${this.splitMixin_splits ? this.splitMixin_splits[i].replyAddress : ''}
      !this.$v.splitMixin_splits.$each[i].fromAddress.maxLength &&
        errors.push('Cannot be more than 255 characters')
      return errors
    },
    previewTextErrors (i) {
      const errors = []
      if (!this.$v.splitMixin_splits.$each[i].previewText.$dirty) return errors
      !this.$v.splitMixin_splits.$each[i].previewText.maxLength &&
        errors.push('Cannot be more than 140 characters')
      return errors
    },
    subjectErrors (i) {
      const errors = []
      if (!this.$v.splitMixin_splits.$each[i].subject.$dirty) return errors
      !this.$v.splitMixin_splits.$each[i].subject.required &&
        errors.push('Subject is required')
      !this.$v.splitMixin_splits.$each[i].subject.maxLength &&
        errors.push('Cannot be more than 255 characters')
      return errors
    },
    cancel () {
      this.$emit('cancelStep', this.stepVal)
    },
    async getAcousticLoginsList () {
      await tenantService.getAcousticLoginsList()
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.domainsInfo = resp
          }
        })
    },
    async getSharedLoginsList () {
      await tenantService.getSharedLoginsList()
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.domains = resp.map(item => item.text).join(', ')
          }
        })
    },
    async getIsSendingDomain (sendingDomain) {
      await tenantService.getIsSendingDomain(sendingDomain)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.isSharedDomain = resp
          }
        })
    },
    async validateEmailSetup () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        let invalidIndices = []
        for (let i = 0; i < this.splitMixin_splits.length; i++) {
          if (this.$v.splitMixin_splits.$each[i].$invalid) {
            invalidIndices.push(i)
          }
        }
        this.splitMixin_expanded = invalidIndices
        if (invalidIndices.length > 0) {
          this.$vuetify.goTo(this.$refs.splitPanel[invalidIndices[0]])
        } else {
          this.$vuetify.goTo(0)
        }
        return
      }

      let invalidHtmlIndices = []
      let warningHtmlIndices = []
      for (let i = 0; i < this.splitMixin_splits.length; i++) {
        this.splitMixin_splits[i].htmlErrors = []
        this.splitMixin_splits[i].htmlWarnings = []
        this.$store.commit('setLoadingMessage', 'Validating Html')
        await mailingService.validateHtml(this.splitMixin_splits[i].id)
          .then(resp => {
            if (resp.errorResponse) {
              this.$store.commit('setGlobalSnackbar', {
                message: resp.errorResponse,
                color: 'error'
              })
            } else {
              if (!resp.isValid) {
                this.splitMixin_splits[i].htmlErrors = resp.errors
                invalidHtmlIndices.push(i)
              }
              if (resp.warnings.length > 0) {
                this.splitMixin_splits[i].htmlWarnings = resp.warnings
                warningHtmlIndices.push(i)
              }
            }
          })
      }

      if (invalidHtmlIndices.length > 0) {
        this.splitMixin_expanded = invalidHtmlIndices
        this.$vuetify.goTo(this.$refs.splitPanel[this.splitMixin_expanded[0]])
      }

      if (warningHtmlIndices.length > 0 && invalidHtmlIndices.length <= 0) {
        this.warningMenu = true
      } else if (warningHtmlIndices.length <= 0 && invalidHtmlIndices.length <= 0) {
        this.advanceFromEmailSetup()
      }
    },
    // async validateSplit (index) {
    //   this.splitMixin_splits[index].htmlErrors = []
    //   this.splitMixin_splits[index].htmlWarnings = []
    //   this.$store.commit('setLoadingMessage', 'Validating Html')
    //   await mailingService.validateHtml(this.splitMixin_splits[index].id)
    //     .then(resp => {
    //       if (resp.errorResponse) {
    //         this.$store.commit('setGlobalSnackbar', {
    //           message: resp.errorResponse,
    //           color: 'error'
    //         })
    //       } else {
    //         if (!resp.isValid) {
    //           this.splitMixin_splits[index].htmlErrors = resp.errors
    //         }
    //         if (resp.warnings.length > 0) {
    //           this.splitMixin_splits[index].htmlWarnings = resp.warnings
    //         }
    //       }
    //     })
    // },
    expandWarnings () {
      let expanded = []
      for (let i = 0; i < this.splitMixin_splits.length; i++) {
        if (this.splitMixin_splits[i].htmlWarnings.length > 0) {
          expanded.push(i)
        }
      }
      this.splitMixin_expanded = expanded
      this.$vuetify.goTo(this.$refs.splitPanel[this.splitMixin_expanded[0]])
    },
    advanceFromEmailSetup () {
      this.$emit('advanceFromStep', this.stepVal)
    },
    async copySplit () {
      this.$store.commit('setLoadingMessage', 'random')
      this.isClicked = true
      let copy = JSON.parse(JSON.stringify(this.splitMixin_splits[this.scrollSplitIndex]))

      if (this.editorUnsaved[this.scrollSplitIndex]) {
        this.saveHtml(this.scrollSplitIndex)
      }

      if (!this.$_splitMixin_isSplit) {
        copy.distribution = 50
        await this.$store.dispatch('mailing/setSplitValue', { index: 0, prop: 'distribution', value: 50 })
      } else if (copy.distribution > this.$_splitMixin_winningDistribution) {
        copy.distribution = this.$_splitMixin_winningDistribution
      }
      await this.$store.dispatch('mailing/createSplit', copy)
      this.splitMixin_expanded.push(this.splitMixin_splits.length - 1)
      this.isClicked = false
    },
    async removeSplit () {
      this.$store.commit('setLoadingMessage', 'random')
      await this.$store.dispatch('mailing/deleteSplit', this.scrollSplitIndex)
      this.$_splitMixin_moveSplitColorToEnd(this.scrollSplitIndex)
      if (this.splitMixin_expanded.includes(this.scrollSplitIndex)) {
        this.splitMixin_expanded.splice(this.splitMixin_expanded.indexOf(this.scrollSplitIndex), 1)
      }
      if (this.splitMixin_splits.length === 1) {
        this.$store.dispatch('mailing/setSplitValue', { index: 0, prop: 'distribution', value: 100 })
        this.focusedSplit = null
        this.splitMixin_expanded = [0]
      }
      this.scrollSplitIndex = -1
    },
    async addSplit () {
      this.$store.commit('setLoadingMessage', 'random')
      this.isClicked = true
      let distribution = 0
      if (!this.$_splitMixin_isSplit) {
        distribution = 50
        await this.$store.dispatch('mailing/setSplitValue', { index: 0, prop: 'distribution', value: 50 })
      } else if (this.$_splitMixin_isTrueSplit) {
        distribution = Math.floor(this.splitMixin_splits[this.splitMixin_splits.length - 1].distribution / 2)
        let remainder = this.splitMixin_splits[this.splitMixin_splits.length - 1].distribution % 2
        await this.$store.dispatch('mailing/setSplitValue', { index: this.splitMixin_splits.length - 1, prop: 'distribution', value: distribution + remainder })
      } else {
        distribution = Math.round(this.$_splitMixin_winningDistribution / 2)
      }
      let split = {
        distribution: distribution,
        replyAddress: this.splitMixin_splits[0].replyAddress
      }
      await this.$store.dispatch('mailing/createSplit', split)
      this.splitMixin_expanded.push(this.splitMixin_splits.length - 1)
      this.isClicked = false
    },
    getAllIndices (arr, val) {
      let indices = []
      let i = -1
      while ((i = arr.indexOf(val, i + 1)) !== -1) {
        indices.push(i)
      }
      return indices
    },
    setMailingValue (prop) {
      this.$store.dispatch('mailing/setMailingValue', { prop })
    },
    setSplitValue (index, prop) {
      let value = this.splitMixin_splits[index][prop] !== null ? this.splitMixin_splits[index][prop].toString() : null
      this.$store.dispatch('mailing/setSplitValue', { index, prop, value })
    },
    closeUploadCreative (item, index) {
      this.uploadCreative[index].menu = false
      this.uploadCreative[index].file = null
    },
    async uploadCreativeSave (item, index) {
      this.uploadCreative[index].errors = []
      let formData = new FormData()
      formData.append('tenantCrmId', this.getMailingTenantCrmId())
      formData.append('mailingId', this.getMailingItemId())
      formData.append('splitId', item.id)
      let file = this.uploadCreative[index].file
      formData.append('files[0]', file)
      this.uploadCreative[index].uploading = true
      await azBlobService.uploadSplitHtml(formData)
        .then(resp => {
          if (resp.errorResponse) {
            this.uploadCreative[index].errors.push(resp.errorResponse)
          } else {
            this.$store.commit('mailing/setSplitValue', { index: index, prop: 'azHtmlLocation', value: resp.azHtmlLocation })
            // this.splitMixin_splits[index].htmlErrors = resp.validation.errors
            // this.splitMixin_splits[index].htmlWarnings = resp.validation.warnings
            this.uploadCreativeSuccess(item, index)
          }
        })
        .finally(() => {
          this.uploadCreative[index].uploading = false
        })
    },
    async uploadCreativeSuccess (item, index) {
      await this.getSplitHtml({ splitIndex: index, updateText: true })
      this.closeUploadCreative(item, index)
    },
    closeParseCreativeText (item, index) {
      this.uploadCreative[index].textMenu = false
    },
    async parseCreativeTextSave (item, index) {
      this.uploadCreative[index].parsingText = true
      if (this.editorUnsaved[index]) {
        await this.saveHtml(index)
      }
      await mailingService.parseCreativeText(item.id)
        .then(resp => {
          if (resp.errorResponse) {
            this.uploadCreative[index].errors.push(resp.errorResponse)
          } else {
            this.splitMixin_splits[index].textAccessibility = resp
            this.setSplitValue(index, 'textAccessibility')
          }
        })
        .finally(() => {
          this.uploadCreative[index].parsingText = false
          this.closeParseCreativeText(item, index)
        })
    },
    closeUploadText (item, index) {
      this.uploadCreative[index].menu2 = false
      this.uploadCreative[index].file2 = null
    },
    async uploadTextSave (item, index) {
      this.uploadCreative[index].errors2 = []
      let formData = new FormData()
      formData.append('tenantCrmId', this.getMailingTenantCrmId())
      formData.append('mailingId', this.getMailingItemId())
      formData.append('splitId', item.id)
      let file = this.uploadCreative[index].file2
      formData.append('files[0]', file)
      this.uploadCreative[index].uploading2 = true
      await mailingService.uploadSplitTextVersion(formData)
        .then(resp => {
          if (resp.errorResponse) {
            this.uploadCreative[index].errors2.push(resp.errorResponse)
          } else {
            this.splitMixin_splits[index].textAccessibility = resp
            this.setSplitValue(index, 'textAccessibility')
            this.closeUploadText(item, index)
          }
        })
        .finally(() => {
          this.uploadCreative[index].uploading2 = false
        })
    },
    clearTextAccessibility (index) {
      this.splitMixin_splits[index].textAccessibility = null
      this.setSplitValue(index, 'textAccessibility')
    },
    openCLChooseDialog (item) {
      item.clChooseDialog = true
    },
    closeCLChooseDialog (item) {
      item.clChooseDialog = false
      item.clHtmlCopying = false
      this.clHtmlUnselected()
      this.templateChange(null)
      this.$refs.clChooseContent[0].unselect()
    },
    clHtmlSelected (clMainItem) {
      this.clHtmlSelectedItem = clMainItem
    },
    clHtmlUnselected () {
      this.clHtmlSelectedItem = null
    },
    templateChange (template) {
      this.clSelectedTemplate = template
    },
    async copyCLTemplateToStage (item, index) {
      item.clHtmlCopying = true
      await this.copyTemplatesHTMLToSplit({ copyFromPath: this.clSelectedTemplate.id, index })
      this.closeCLChooseDialog(item)
    },
    async copyCLHtmlToStage (item, index) {
      item.clHtmlCopying = true
      await this.copyCLHtmlToSplit({ copyFromPath: this.clHtmlSelectedItem.id, index })
      this.closeCLChooseDialog(item)
    },
    async saveHtml (index) {
      this.$store.commit('setLoadingMessage', `Saving Creative ${this.$_splitMixin_splitLetters[index]}`)
      // if (this.inCodeView[index]) {
      //   let editor = this.$refs.froalaEditor.find(x => x.$el.classList.contains(this.$_splitMixin_splitLetters[index])).getEditor()
      //   this.$set(this.splitMixin_splits[index], 'html', editor.codeView.get())
      // }
      let resp = await this.saveSplitHtml({
        html: this.splitMixin_splits[index].html,
        index: index
      })
      if (resp.errorResponse) {
        this.$store.commit('setGlobalSnackbar', {
          message: resp.errorResponse,
          color: 'error'
        })
      } else {
        this.splitMixin_splits[index].htmlErrors = resp.validation.errors
        this.splitMixin_splits[index].htmlWarnings = resp.validation.warnings
      }
      setTimeout(() => {
        this.$set(this.editorUnsaved, index, false)
      }, 1)
    },
    async saveAllHtmls () {
      var _this = this
      this.splitMixin_splits.forEach((split, index) => {
        _this.saveHtml(index)
      })
    },
    async validateAllHtmls () {
      var _this = this
      this.splitMixin_splits.forEach((split, index) => {
        _this.validateSplit({ index })
      })
    },
    onIntersect (entries, observer) {
      if (entries[0].intersectionRatio > this.scrollMaxThreshold) {
        this.scrollMaxThreshold = entries[0].intersectionRatio
        this.scrollSplitIndex = this.$_splitMixin_splitLetters.indexOf(entries[0].target.classList[1])
      } else if (this.scrollSplitIndex === this.$_splitMixin_splitLetters.indexOf(entries[0].target.classList[1])) {
        this.scrollMaxThreshold = entries[0].intersectionRatio
        // if (this.scrollMaxThreshold <= 0.1) {
        //   this.scrollSplitIndex = -1
        // }
      }
    },
    async loadOptOuts () {
      this.optOutLoading = true
      let resp = await tenantService.getOptOutList(this.$store.getters['mailing/getMailingTenantCrmId']())
      this.optOutSelect = resp
      this.optOutLoading = false
    },
    openOptOutPreviewDialog (editable) {
      this.optOutPreviewDialog = true
      this.optOutEditable = editable
    },
    closeOptOutPreviewDialog () {
      this.optOutPreviewDialog = false
      this.optOutEditable = false
    },
    closeCreateOptOut () {
      this.createOptOutMenu = false
      this.createOptOut = ''
    },
    async createOptOutSave (name) {
      this.$v.createOptOut.$touch()
      if (this.$v.createOptOut.$invalid) {
        return
      }
      this.optOutLoading = true
      let id = await tenantService.createOptOut({
        tenantCrmId: this.$store.getters['simulatedCustomerCrmId'](),
        displayName: name
      })
      await this.loadOptOuts()
      this.optOutId = id
      this.closeCreateOptOut()
      this.openOptOutPreviewDialog(true)
    },
    toggleEditorMenu (split, menuName, isOn) {
      let options = ['devicePreview', 'dragAndDrop']
      if (isOn) {
        options.forEach(item => {
          split[item] = menuName === item
        })
      } else {
        split[menuName] = isOn
      }
    },
    toggleTextMenu (split, menuName, isOn) {
      split[menuName] = isOn
    },
    async downloadSplit (splitId) {
      var _this = this
      await azBlobService
        .downloadSplit(splitId)
        .then(function (resp) {
          _this.$_downloadFileMixin_downloadResponse(resp)
        })
    },
    changedDevice (device, split, i) {
      split.deviceWidth = device.width
      // TODO: can remove if desktop is not option for this page
      // let options = { offset: 70 }
      // if (split.deviceWidth > 600) {
      //   options.offset = 10
      // }
      // setTimeout(() => {
      //   this.$vuetify.goTo(this.$refs.splitDevicePreview[i], options)
      // }, 100)
    },
    removeEndLine (str) {
      return str.replace(/\n/g, '')
    }
    // async getSentimentAnalysis(split, text) {
    //   Vue.set(split.subjectSentiment, 'loading', true)
    //   let resp = await mailingService.sentimentAnalysis(text)
    //   Vue.set(split.subjectSentiment, 'positive', Math.round(resp.positive * 100))
    //   Vue.set(split.subjectSentiment, 'neutral', Math.round(resp.neutral * 100))
    //   Vue.set(split.subjectSentiment, 'negative', Math.round(resp.negative * 100))
    //   Vue.set(split.subjectSentiment, 'loading', false)
    // }
  }
}
</script>

<style lang="scss" scoped>
.v-progress-linear, .v-input__slider {
  display: inline-block;
  vertical-align: middle;
}
.v-slider__track-container {
  height: 4px !important;
}
.fab-container {
  z-index: 6;
  position: fixed;
  top: 50%;
  transition: 0.25s;
}
.fab-container .v-size--large {
  height: 50px;
  width: 50px;
}
</style>
